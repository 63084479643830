import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { GoogleService } from 'src/app/core/google/google.service';
import { SmeService } from 'src/app/core/sme/sme.service';

@Component({
    selector: 'app-share-classroom',
    templateUrl: './share-classroom.component.html',
    styleUrl: './share-classroom.component.scss'
})
export class ShareClassroomComponent implements OnDestroy {

    paramMapSubscription: Subscription | undefined;

    public isLoading = false;
    urlToShare = '';
    safeUrlToShare: SafeResourceUrl = '';


    constructor(
        private route: ActivatedRoute,
        private googleService: GoogleService,
        private smeService: SmeService,
        private sanitizer: DomSanitizer
    ) {
        this.paramMapSubscription = this.route.params.subscribe((params: Params) => {
            const mapId = params['mapId'];
            this.share(mapId);
        });
    }

    share(mapId: string): void {
        console.log('Preparing mapId: ' + mapId);
        this.isLoading = true;
       // this.googleService.getClassroomFolderId().then((folderId: any) => {
            this.smeService.uploadMapToDrive(mapId + '', '').subscribe({
                next: (data: any) => {
                    const googleUri = `https://drive.google.com/file/d/${data.googleId}?usp=drive_link`;
                    this.urlToShare = `https://classroom.google.com/share?url=${encodeURIComponent(googleUri)}`;
                    this.isLoading = false;
                    this.safeUrlToShare = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlToShare);
                    (window as any).location = this.urlToShare;
                },
                error: (error) => {
                    console.error(JSON.stringify(error));
                    this.isLoading = false;
                }
            });
       // });
    }

    ngOnDestroy(): void {
        if (this.paramMapSubscription) { this.paramMapSubscription.unsubscribe(); }
    }

}
