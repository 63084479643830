import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import { Observable, Subscription } from 'rxjs';
import { FirebaseAuthService } from '../core/firebase/firebase-auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { I18nService, extract } from '../core/i18n.service';
import { SmeService } from '../core/sme/sme.service';
import { MatDialog } from '@angular/material/dialog';
import { CurrentUserLoggingInDto, SetPasswordComponent } from '../login-form/set-password/set-password.component';
import { SetFullnameComponent } from './set-fullname/set-fullname.component';
import { MapStateService } from '../shared/map-state.service';
import { MessageBoxService } from '../shared/dialog/messagebox.service';
import { ConfirmationService } from '../shared/dialog/confirmation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Location} from '@angular/common';



@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})


export class UserProfileComponent implements OnInit {


    selectedFiles?: FileList;
    selectedFileNames: string[] = [];
    imageFile: File | null = null;
    imageUrl: any = 'assets/icons8-utente-48.png';
    loginErrorMessage = '';
    clickableMsg = false;
    progressInfos: any[] = [];
    message: string[] = [];
    editPassword = false;
    editFullName = false;
    editMail = false;
    editing: boolean = false;

    previews: string[] = [];
    imageInfos?: Observable<any>;
    APP_ID = environment.configFirebase.messagingSenderId;

    public isNativeGoogleUser = this.firebaseAuthService.isNativeGoogleUser();
    userGoogleEmail: string | null | undefined = '';

    client_id = environment.firebaseClientId;
    public user = {
        id: '',
        uid: '',
        email: '',
        displayName: '',
        picture: '',
        refreshToken: '',
        token: ''
    };
    isLoading = false;
    isError = false;
    showingError = false;
    errorMessage = '';
    errorOnPassword = false;
    errorOnSavePassword = false;
    userName: string = '';
    fullName: string = '';
    highlightGoogleButton: boolean = false;
    // googleDescButton = this.translateService.instant('LINK_WITH_GOOGLE');

    public genLang = '';

    public currLanguage = '';
    createUserParams: boolean = false;
    paramMapSubscription: Subscription | undefined;
    form: FormGroup = new FormGroup({
        username: new FormControl('', [Validators.required, Validators.email]),
        fullname: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
        imageUrl: new FormControl('', []),
        language: new FormControl('', [])
    });
    hide: boolean = true;
    // @Input() isPasswordLess = false;

    showHidePsw(): void {
        this.hide = !this.hide;
    }


    constructor(
        private _location: Location,
        private firebaseAuthService: FirebaseAuthService,
        private authenticationService: AuthenticationService,
        private router: Router,
        public mapStateService: MapStateService,
        private smeService: SmeService,
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private cookieService: CookieService,
        private confirmationService: ConfirmationService,
        private snackBar: MatSnackBar,
        private i18nService: I18nService) { }
    ngOnInit(): void {
        //  this.isPasswordLess = (localStorage.getItem('isPasswordless') === '1');
        //  if (!this.isPasswordLess) {
        this.paramMapSubscription = this.route.params.subscribe((params: Params) => {
            this.imageUrl = this.authenticationService.getUserImageUrl();
            this.user = {
                id: params['id'],
                uid: params['id'],
                email: this.authenticationService.getUserEmail(),
                displayName: this.authenticationService.getUserFullname(),
                picture: this.authenticationService.getUserImageUrl(),
                refreshToken: this.authenticationService.getUserRefreshToken(),
                token: this.authenticationService.getUserAccessToken()
            };
            this.setData();
        });
        if (this.route.snapshot.fragment) {
            this.highlightGoogleButton = this.route.snapshot.fragment.includes('highlight=1');
        }
        // } else {
        //     this.setData();
        // }
        this.firebaseAuthService.auth.onAuthStateChanged((user: any) => {
            if (user !== null && !this.createUserParams) {
                this.imageUrl = user.photoURL;
                this.form.controls['imageUrl'].setValue(this.imageUrl);
                this.fullName = user.displayName;
                this.userName = user.email;
            }
        });

        this.currLanguage = this.i18nService.language;
    }

    private setData() {
        this.fullName = this.user.displayName;
        this.userName = this.user.email;

        this.form.controls['username'].setValue(this.user.email);
        this.form.controls['fullname'].setValue(this.user.displayName);

        //  this.form.controls['imageUrl'].setValue(this.user.picture);
        this.form.controls['username'].disable();
        this.form.controls['imageUrl'].setValue(this.user.picture);
        const language = this.cookieService.get('GUILanguage');
        const langItem = this.findLangItem(language);
        this.currLanguage = langItem;
        this.imageUrl = this.user.picture;
        this.form.controls['language'].setValue(langItem);

        const authCred = this.authenticationService.credentials;
        if (authCred) {
            this.userGoogleEmail = authCred.googleUserEmail;
        }
    }

    // isPasswordLessUser() {
    //     // return this.firebaseAuthService.isPasswordLessUser;
    //     return this.isPasswordLess;
    // }

    showEditPassword() {
        if (!this.editing) {
            this.editPassword = true;
            this.editing = true;
        }
    }

    onSavedPassword(user?: CurrentUserLoggingInDto): void {
        if (user) {

            this.firebaseAuthService.reauthenticate(user.email, user.oldPassword).then(() => {
                this.firebaseAuthService.updatePassword(user.password).then(() => {
                    this.editPassword = false;
                    this.editing = false;
                    const snackBarRef = this.snackBar.open(this.translateService.instant('PASSWORD_UPDATED'), '', { duration: 5 * 1000 });

                }).catch((error) => {
                    this.error = error;
                    this.errorOnSavePassword = true;
                    console.log(this.error);
                });
            }).catch((error) => {
                this.error = error;
                this.errorOnPassword = true;
                console.log(this.error);

            });
            // this.fullName = this.firebaseAuthService.auth.currentUser?.displayName ? this.firebaseAuthService.auth.currentUser?.displayName : '';
        } else {
            this.editPassword = false;
            this.editing = false;
        }
    }

    showEditFullname() {
        if (!this.editing) {
            this.editFullName = true;
            this.editing = true;
        }
    }

    onSavedFullName(newName: string): void {
        this.editFullName = false;
        this.fullName = newName;
        this.editing = false;
        // this.fullName = this.firebaseAuthService.auth.currentUser?.displayName ? this.firebaseAuthService.auth.currentUser?.displayName : '';
        this.authenticationService.reloadCredentials();
        if (this.authenticationService.credentials) {
            this.authenticationService.credentials.fullName = newName;
            this.authenticationService.setCredentials(this.authenticationService.credentials);
        }
    }

    findLangItem(language: string): any {
        let l = null;
        this.i18nService.supportedLanguages.forEach((lang) => {
            if (lang.substring(0, 2) === language) {
                l = lang;
            }
        });
        return l;
    }

    get currentLanguage(): string {
        return this.i18nService.language;
    }

    get languages(): string[] {
        return this.i18nService.supportedLanguages;
    }

    linkedWithGoogle() {
        // return (this.autenticationService.credentials && this.autenticationService.credentials.username ? true : false);
        return this.authenticationService.linkedWithGoogle();
    }

    languageName(lang: string): string {
        return this.translateService.instant(lang);
    }
    setLanguage(lang: any) {
        const language = lang.value + '';
        this.form.controls['language'].setValue(this.currLanguage);
        this.currLanguage = language;
        this.i18nService.language = language;
        this.genLang = language.substring(0, 2);

        this.cookieService.set('GUILanguage', this.genLang);
        localStorage.setItem('language', language);
        localStorage.setItem('locale', this.genLang);
        document.querySelector('html')?.setAttribute('lang', this.genLang);
        (window as any).WEBSPELLCHECKER_CONFIG.localization = this.genLang;
    }


    linkWithGoogle() {
        this.firebaseAuthService.linkwithGoogle().then((result) => {
            console.log(result);
            const data = this.getGoogleUserInfo(result.tokenResponse);
            const authCred = this.authenticationService.credentials;
            if (authCred) {
                authCred.googleUserEmail = data.email;
                this.userGoogleEmail = data.email;
                this.authenticationService.setCredentials(authCred, true);
            }
            this.smeService.writeGoogleUserData(data)
                .subscribe({
                    next: (data) => {
                        console.log(data);
                    },
                    error: (error) => {
                        console.log(error);
                    }
                });

        }).catch((error) => {
            console.log(error);
            this.confirmationService.confirm(
                this.translateService.instant(extract('WARNING')),
                this.translateService.instant(extract('EXISTING_ACCOUNT')),
                this.translateService.instant(extract('BUTTON_OK')),
                '').subscribe(() => {

                });

            //alert('account già presente nella piattaforma');
        });
    }


    unlinkGoogle() {
        this.firebaseAuthService.unlinkGoogle().then((result) => {
            //if (result === true) {
            const authCred = this.authenticationService.credentials;
            if (authCred) {
                authCred.googleUserEmail = '';
                this.userGoogleEmail = '';
                this.authenticationService.setCredentials(authCred, true);
            }
            this.smeService.unlinkGoogleEmailForUser().subscribe();
            // }
        }).catch((error) => {
            const authCred = this.authenticationService.credentials;
            if (authCred) {
                authCred.googleUserEmail = '';
                this.authenticationService.setCredentials(authCred, true);
            }
            this.smeService.unlinkGoogleEmailForUser().subscribe();
        })
    }


    getGoogleUserInfo(googleUserData: any): any {
        const raw = JSON.stringify(googleUserData.rawUserInfo);
        const data = {
            access_token: safe(googleUserData, ['oauthAccessToken'], ''),
            authuser: 0,
            code: '',
            email: safe(googleUserData, ['email'], ''),
            expiry_date: 0,
            family_name: safe(googleUserData, ['lastName'], ''),
            fullName: safe(googleUserData, ['fullName'], ''),
            given_name: safe(googleUserData, ['firstName'], ''),
            id: safe(googleUserData, ['sub'], ''), // <--sub
            id_token: safe(googleUserData, ['idToken'], ''),
            picture: safe(raw, ['picture'], ''),
            prompt: '',
            refresh_token: safe(googleUserData, ['refreshToken'], ''),
            scope: safe(raw, ['granted_scopes'], ''),
            state: '',
            token_type: 'Bearer'
        };
        return data;
    }

    submit() {
        if (this.form.valid) {
            this.form.controls['imageUrl'].setValue(this.imageUrl);
            this.form.controls['language'].setValue(this.currLanguage);
            // if (this.createUserParams) {
            //     this.createUser(this.form.value)
            // } else {
            this.updateUser(this.form.value)
            // }
            //   this.submitCreate.emit(this.form.value);
        }
    }

    get f() { return this.form?.controls; }

    reset() {
        if (this.createUserParams) {
            this.router.navigate(['home']);
        } else {
            this.router.navigate(['login']);
        }

    }


    updatePassword() {
        this.firebaseAuthService.updatePassword(this.form.get("passwordConfirm")?.value).then(() => {
            const snackBarRef = this.snackBar.open(this.translateService.instant('PASSWORD_UPDATED'), '', { duration: 5 * 1000 });
        });
    }

    resetPassword() {
        this.firebaseAuthService.auth.languageCode = this.translateService.currentLang;
        this.firebaseAuthService.resetPassword(this.form.get("username")?.value)
            .then(() => {
                // Password reset email sent!
                alert('Password reset email sent!');
            })
            .catch((error) => {
                this.error = error;
            });
    }

    updateUser(event: any) {
        this.isLoading = true;
        this.showingError = false;
        console.log(`Login...`);
        if (navigator.onLine) {
            console.log(`ONLINE`);
            this.firebaseAuthService.updateUserProfile(event.fullname, this.imageFile)
                .then((userCred: any) => {
                    alert('Account modified!');
                    this.client_id = environment.firebaseClientId;
                    if (userCred != '') {
                        this.user = {
                            id: userCred.uid,
                            uid: userCred.uid,
                            email: userCred.email,
                            displayName: userCred.displayName ? userCred.displayName : '',
                            picture: userCred.photoURL,
                            refreshToken: userCred.stsTokenManager.refreshToken,
                            token: userCred.stsTokenManager.accessToken
                        };
                        const UserInfo = {

                            state: 'password',
                            access_token: userCred.stsTokenManager.accessToken,
                            email: userCred.email,
                            fullName: userCred.displayName ? userCred.displayName : '',
                            id: userCred.uid,
                            id_token: userCred.stsTokenManager.idToken,
                            picture: userCred.photoURL,
                            refresh_token: userCred.stsTokenManager.refreshToken
                        };
                    }
                    if (event.passwordConfirm != '') {
                        return this.firebaseAuthService.updatePassword(event.password);
                    } else {
                        return Promise.resolve(true);
                    }
                }).then(() => {
                    this.isLoading = false;
                    // this.router.navigate(['/login'], { queryParams: { userInfo: JSON.stringify(UserInfo) } });
                    this.router.navigate(['home']);

                }).catch(error => {
                    console.error(error);
                    this.isLoading = false;
                    this.showError(error);
                });
        } else {
            console.error(`ERROR: OFFLINE`);
            this.showError('OFFLINE_LOGIN');
        }
    }

    // createUser(event: any) {
    //     this.isLoading = true;
    //     this.showingError = false;
    //     console.log(`Login...`);
    //     if (navigator.onLine) {
    //         console.log(`ONLINE`);

    //         this.firebaseAuthService.createUser(event.username, event.password, event.fullname, this.selectedFileNames[0])
    //             .then((userCred: any) => {

    //                 this.client_id = environment.firebaseClientId;

    //                 this.user = {
    //                     id: userCred.uid,
    //                     uid: userCred.uid,
    //                     email: userCred.email,
    //                     displayName: userCred.displayName ? userCred.displayName : '',
    //                     picture: userCred.photoURL,
    //                     refreshToken: userCred.stsTokenManager.refreshToken,
    //                     token: userCred.stsTokenManager.accessToken
    //                 };
    //                 const UserInfo = {

    //                     state: 'password',
    //                     access_token: userCred.stsTokenManager.accessToken,
    //                     email: userCred.email,
    //                     fullName: userCred.displayName ? userCred.displayName : '',
    //                     id: userCred.uid,
    //                     id_token: userCred.stsTokenManager.idToken,
    //                     picture: userCred.photoURL,
    //                     refresh_token: userCred.stsTokenManager.refreshToken
    //                 };

    //                 this.router.navigate(['/login'], { queryParams: { userInfo: JSON.stringify(UserInfo) } });


    //                 //  this.router.navigate(['/loggedin'], { queryParams: { userInfo: JSON.stringify(UserInfo) } });
    //                 //this.loginStep1();
    //             }).catch(error => {
    //                 console.error(error);
    //                 this.isLoading = false;
    //                 this.showError(error);
    //             });
    //     } else {
    //         console.error(`ERROR: OFFLINE`);
    //         this.showError('OFFLINE_LOGIN');
    //     }
    // }

    private showError(errorCode: any) {
        // console.log(`ERRORCODE: ${errorCode}`);
        if (errorCode) {
            this.loginErrorMessage = errorCode //this.translateService.instant(errorCode);
            this.clickableMsg = false;
        } else {
            this.loginErrorMessage = this.translateService.instant('LOGIN_COOKIES_URL');
            this.clickableMsg = true;
        }
        this.showingError = true;
        this.isLoading = false;
    }


    enabledForm() {
        let en = false;
        if (this.form) {
            en = this.form.valid;
        }
        return en;
    }

    selectFiles(event: any): void {

        this.selectedFileNames = [];
        this.selectedFiles = event.target.files;
        this.imageFile = null;

        this.previews = [];
        if (this.selectedFiles && this.selectedFiles[0]) {
            const numberOfFiles = this.selectedFiles.length;
            for (let i = 0; i < numberOfFiles; i++) {
                const reader = new FileReader();

                reader.onload = (e: any) => {
                    console.log(e.target.result);
                    this.previews.push(e.target.result);
                    this.imageUrl = e.target.result;
                };

                reader.readAsDataURL(this.selectedFiles[i]);
                this.imageFile = this.selectedFiles[i];

                this.selectedFileNames.push(this.selectedFiles[i].name);
                this.firebaseAuthService.updateUserProfileImage(this.imageFile);

                const cred = this.authenticationService.reloadCredentials();
                if (cred !== null && cred !== undefined) {
                    cred.imageURL = this.imageUrl;
                    this.authenticationService.setCredentials(cred);

                }
            }
        }
    }

    close() {
        if (this.highlightGoogleButton) {
            this._location.back();
        } else {
        this.router.navigate(['home']);
        }
    }

    removeAvatar() {
        this.imageFile = null;
        this.imageUrl = null;
        this.previews = [];
        this.selectedFileNames = [];
        this.firebaseAuthService.removeUserProfileImage().then(() => {
            this.user.picture = '';

            const cred = this.authenticationService.reloadCredentials();
            if (cred !== null && cred !== undefined) {
                cred.imageURL = '';
                this.authenticationService.setCredentials(cred)
            }

        });
    }

    @Input() error: string | null = "";


    @Output() submitCreate = new EventEmitter();

    //     goOn() {
    //         if (this.fullName && this.fullName.trim() != '') {
    //             // if (this.isPasswordLess) {
    //             //     const demoFromStart = localStorage.getItem('demoFromStart');
    //             //     if (demoFromStart) {
    //             //         this.smeService.activateDemo(this.fullName, this.userName).subscribe(() => {
    //             //             this.router.navigate(['/home']);
    //             //         });
    //             //     } else {
    //             this.router.navigate(['/demo-user']);
    //             //   }
    //             // }
    //         } else {
    //             this.showError('FULLNAME_REQUIRED');
    //         }
    //     }
}

function safe(value: any, arrFields: Array<string>, defValue: any) {
    if (value && arrFields) {
        let data = value;
        let i = 0;
        while (data && i < arrFields.length) {
            if (data) {
                data = data[arrFields[i]];
                i++;
            } else {
                data = null;
            }
        }
        if (data != null && data != undefined) {
            return data;
        } else {
            return defValue ?? null;
        }
    }
    return defValue ?? null;
}


