import env from './.env.json';
import rel from './.rel.json';

export const environment = {
    env: 'test',
    test: true,
    production: false,
    version: env.npm_package_version + ' (test)',
    build: rel.release,
    serverUrl: '',
    defaultLanguage: 'it-IT',
    supportedLanguages: [
        'it-IT',
        'en-GB'
    ],
    configFirebase: {
        apiKey: 'AIzaSyAizWG0frrVbc95w6EYu9iWolR2uko3rnU',
        authDomain: 'supermappextest.firebaseapp.com',
        databaseURL: 'https://supermappextest.firebaseio.com',
        projectId: 'supermappextest',
        storageBucket: 'supermappextest.appspot.com',
        messagingSenderId: '470121516576'
    },
    firebaseClientId: '470121516576-pttbh6vccfjd2b86n89nqu97j4ojuasr.apps.googleusercontent.com',
    scope: 'email profile https://www.googleapis.com/auth/drive.file  https://www.googleapis.com/auth/drive.install'
};
