import { Component, OnDestroy, OnInit } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ItemNode, ItemNodeFlat, MAPNODETYPE, OutlineService } from './outline.service';
import { QuickEditService } from '../../shared/commands/quick-edit.service';
import { ExtraItem, ExtraService } from '../commands/extra.service';
import { TranslateService } from '@ngx-translate/core';
import { SmService } from 'supermappe-core';
import { MapStateService } from '../../shared/map-state.service';
import { TtsService } from '../../shared/commands/tts.service';
import { Logger } from '../../core/logger.service';
import { ResizeEvent } from 'angular-resizable-element';
import { clamp } from 'lodash';
import { UiConstants } from '../../shared/ui-constants';
import { CustomSelectElement } from '../../map-edit/toolbar/custom-toolbar/custom-select-element-dto';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { UserPreferenceService } from '../../shared/user-preference.service';
import { SpeechRecognitionService } from '../../shared/speech/speechrecognition.service';
import { SpeechRecognitionResult } from '../../shared/speech/speech-recognition-result';
import { ArrangeService } from 'src/app/shared/commands/arrange.service';
import { MathService } from 'src/app/shared/commands/math.service';
import { DeviceService } from 'src/app/core/device.service';

const logger: Logger = new Logger('outline');

/** Map from flat node to nested node. This helps us finding the nested node to be modified */
const flatNodeMap = new Map<ItemNodeFlat, ItemNode>();

/** Map from nested node to flattened node. This helps us to keep the same object for selection */
const nestedNodeMap = new Map<ItemNode, ItemNodeFlat>();

enum TalkTextType {
    EDGE_NODE,
    EDGE,
    NODE
}

@Component({
    selector: 'app-outline',
    templateUrl: './outline.component.html',
    styleUrls: ['./outline.component.scss']
})
export class OutlineComponent implements OnInit, OnDestroy {

    public canAddSibling = false;
    public canAddChild = false;
    public canDelete = false;
    public canEditItem = false;
    public canMoveUp = false;
    public canMoveDown = false;
    public canMoveIn = false;
    public canMoveOut = false;
    public canSelectSubtree = false;
    public canQuickInput = false;
    public canArrange = false;

    public outlineQuickInputState = false;
    public outlineViewNodeImageState = true;
    public outlineViewNodeTooltip = this.translateService.instant('OUTLINE_NODE_VIEW_IMAGE_HIDE');

    public selectedNodeFlat: ItemNodeFlat | undefined = undefined;
    public selectedNodeFlatId: string = '';
    public selectedNode: ItemNode | undefined = undefined;
    public editingNodeFlatId: string = '';
    public editTitleText: string = '';

    private isMoving: boolean = false;
    private isAfterUpdate: boolean = false;

    hoverNodeFlat: ItemNodeFlat | undefined;
    hoverHasParent: boolean = false;
    hoveringType: MAPNODETYPE = MAPNODETYPE.NONE;
    dragNodeFlat: ItemNodeFlat | undefined;
    dragNodeExpandOverWaitTimeMs = 300;
    dragNodeExpandOverNode: any;
    dragNodeExpandOverTime: number = 0;
    nodeLevel: number = 0;
    collapsedNodeDrag: ItemNodeFlat | undefined;
    expTables: Map<string, boolean> | null = null;
    newNodeId: string = '';
    needToEditSelectedItem: boolean = false;
    deleteTime: number = 0;
    outlineContainerHtmlElement: any;

    elementInScrollViewTimeout: any;
    elementEditTimeout: any;
    elementEditFocusTimeout: any;

    //TTS
    public _currLanguage = '';//UiConstants.LANGUAGES[0].value;
    public selectedLangIcon = ''; //UiConstants.LANGUAGES[0].icon;
    public languages: CustomSelectElement[] = new Array<CustomSelectElement>();
    public rateValue = 1;
    public speechEditEnabled = false;
    public isTalking: boolean = false;
    public talkingNodeFlat: ItemNodeFlat | undefined = undefined;
    public talkingNodeFlatId: string = '';
    private wasTalkingEdge: boolean = false;

    private onGetVoicesSubscription: Subscription | undefined;
    private onSpeechEditEnableSubscription: Subscription | undefined;
    private speechRecordSubscription: Subscription | undefined;
    private onTTSTalkingChanged: Subscription | undefined;
    private onTTSTalkingStart: Subscription | undefined;
    private onTTSTalkingEnd: Subscription | undefined;

    private isEditOpen: boolean = false;
    private startEditTitleText: string = '';

    public isMobileOrTabletDevice: boolean = false;

    constructor(
        private smService: SmService,
        private quickEditService: QuickEditService,
        private extraService: ExtraService,
        public outlineService: OutlineService,
        public translateService: TranslateService,
        private mapStateService: MapStateService,
        private ttsService: TtsService,
        private authService: AuthenticationService,
        private userPreferencesService: UserPreferenceService,
        private speechRecognitionService: SpeechRecognitionService,
        private arrangeService: ArrangeService,
        private mathService: MathService,
        public deviceService: DeviceService
    ) {
        this.isMobileOrTabletDevice = deviceService.isMobileOrTabletDevice();

        this.expTables = new Map<string, boolean>();
        this.outlineService.onOutlineUpdated.subscribe(data => {
            //fermo lettura
            this.TTSStop();
            // Reset state
            if (!this.isMoving) {
                this.resetEditItem();
            }
            this.selectedNode = undefined;
            this.selectedNodeFlat = undefined;
            // Update tree data
            this.dataSource.data = [];
            this.dataSource.data = data;

            // Update selection - restore old selected id
            if (this.selectedNodeFlatId) {
                this.selectedNodeFlat = this.treeControl.dataNodes.find(nodeFlat => nodeFlat.id === this.selectedNodeFlatId);
                if (this.selectedNodeFlat) {
                    this.selectedNode = flatNodeMap.get(this.selectedNodeFlat);
                }
            }
            this.updateToolbar();
            this.updateLanguageFlag();

            const usermail = this.authService.getUserEmail();
            if (usermail != '') {
                const mapId = this.mapStateService.id;
                const filter: Array<string> = [usermail, mapId];
                const savedNodeWidth = Number(userPreferencesService.getCookie("outline_node_width", filter));
                if (!isNaN(savedNodeWidth) && savedNodeWidth > 0) {
                    this.currentNodeWidth = savedNodeWidth;
                }
            }

            //Expand nodes automatically
            setTimeout(() => {
                this.setExpandedNodes(data, this.expTables);
                if (this.selectedNode) {
                    this.IsAfterUpdateStart();
                    this.bringElementIntoScrollView(this.selectedNode.id, 200).then(() => {
                        if (this.outlineQuickInputState || this.needToEditSelectedItem) {
                            this.uiEditItem(0);
                        }
                    });
                }
            }, 0);
        });

        this.outlineService.onOutlineSelectionUpdated.subscribe(_selectionData => {
            if (_selectionData) {
                if (!this.outlineQuickInputState && !this.needToEditSelectedItem) {
                    if (this.editTitleText) {
                        this.closeEditingNode(true);
                    }
                    // Reset state
                    this.resetEditItem();
                }
                this.selectedNode = undefined;
                this.selectedNodeFlat = undefined;
                if (_selectionData.id) {
                    // Update selection
                    this.selectedNodeFlatId = _selectionData?.id + '_0';
                    this.selectedNodeFlat = this.treeControl.dataNodes.find(nodeFlat => nodeFlat.id === this.selectedNodeFlatId);
                    if (this.selectedNodeFlat) {
                        this.selectedNode = flatNodeMap.get(this.selectedNodeFlat);
                        if (this.selectedNode && this.selectedNode.tableId && this.isDeleteItemExpired()) {
                            this.ensureVisible(this.selectedNode);
                        }

                        if (this.selectedNode && (this.outlineQuickInputState || this.needToEditSelectedItem)) {
                            this.IsAfterUpdateStart();
                            this.bringElementIntoScrollView(this.selectedNode.id, 200).then(() => {
                                this.uiEditItem(0);
                            });
                        }
                        else if (this.selectedNode) {
                            this.bringElementIntoScrollView(this.selectedNode.id, 200);
                        }
                    }
                } else {
                    // No selection
                    this.outlineQuickInputState = false;
                    this.editTitleText = '';
                    this.editingNodeFlatId = '';
                    this.selectedNodeFlatId = '';
                    this.selectedNodeFlat = undefined;
                    this.selectedNode = undefined;
                }
            }
            this.updateToolbar();
            this.updateLanguageFlag();
        });

        this.outlineService.onNodeStateUpdated.subscribe(data => {
            if (data && data.data) {
                const id = data.data.id;
                const state = data.state;
                if (id) {
                    const nodeItemFlat = this.treeControl.dataNodes.find(nodeFlat => nodeFlat.id === id + '_0');
                    if (nodeItemFlat) {
                        if (state) {
                            this.treeControl.expand(nodeItemFlat)
                        } else {
                            this.treeControl.collapse(nodeItemFlat)
                        }
                    }
                }
            }
        });

        this.initTTS();
    }

    ngOnInit() {
        this.outlineContainerHtmlElement = document.getElementById("outlineScroll");
        this.initSm();
        this.outlineService.outlineOpened = true;
        // this.smService.setEnableKeyPresses(false);
        this.smService.askOutline();
    }

    ngOnDestroy(): void {
        this.TTSStop();

        if (this.quickEditService.isQuickEditEnabled()) {
            this.quickEditService.toggleQuickEdit();
        }
        if (this.quickEditService.isSpeechEditEnabled()) {
            this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.NOTES);
        }

        this.outlineService.outlineOpened = false;
        // this.smService.setEnableKeyPresses(true);
        if (this.onGetVoicesSubscription) { this.onGetVoicesSubscription.unsubscribe(); }
        if (this.onSpeechEditEnableSubscription) { this.onSpeechEditEnableSubscription.unsubscribe(); }
        if (this.speechRecordSubscription) { this.speechRecordSubscription.unsubscribe(); }
        if (this.onTTSTalkingChanged) { this.onTTSTalkingChanged.unsubscribe(); }
        if (this.onTTSTalkingStart) { this.onTTSTalkingStart.unsubscribe(); }
        if (this.onTTSTalkingEnd) { this.onTTSTalkingEnd.unsubscribe(); }
    }

    private initSm() {
        // Define strings
        const strings = this.smService.getStrings();
        strings.ARRANGE.TABLE_NAME = this.translateService.instant('OUTLINE_ARRANGE_TABLE');
        this.smService.setOutline(strings);
    }

    public canEdit() {
        return (this.outlineService && this.outlineService.canEdit ? this.outlineService.canEdit : false);
    }

    private initTTS() {
        this.speechEditEnabled = false;
        this.setLanguage(''/*this._currLanguage*/);

        this.rateValue = this.getSavedReadSpeedByCurrentLanguage();
        this.ttsService.voiceRate = this.rateValue;

        this.onTTSTalkingStart = this.ttsService.TalkingStart.subscribe(() => {
            this.isTalking = true;

        });

        this.onTTSTalkingEnd = this.ttsService.TalkingEnd.subscribe(() => {
            this.isTalking = false;

            if (this.wasTalkingEdge)
                this.talkOutlineElement(true);
            else
                this.talkOutlineElement(false);
        });

        this.onTTSTalkingChanged = this.ttsService.isTalkingStateChanged.subscribe((talking: boolean) => {

        });

        this.onGetVoicesSubscription =
            this.ttsService.onGetVoices.subscribe((voices: SpeechSynthesisVoice[]) => {
                if (this.deviceService.isMobileOrTabletDevice()) {
                    this.languages = UiConstants.initLanguagesMobile(voices, this.translateService);
                } else {
                    this.languages = UiConstants.initLanguagesDesktop(voices, this.translateService);
                }
            });
        if (this.ttsService.voices) {
            if (this.deviceService.isMobileOrTabletDevice()) {
                this.languages = UiConstants.initLanguagesMobile(this.ttsService.voices, this.translateService);
            } else {
                this.languages = UiConstants.initLanguagesDesktop(this.ttsService.voices, this.translateService);
            }
        }

        // this.onSpeechEditEnableSubscription =
        //     this.quickEditService.onSpeechEditEnable.subscribe((enabled: boolean) => {
        //         if (this.quickEditService.origin === this.quickEditService.ORIGIN.OUTLINE) {
        //             this.speechEditEnabled = enabled;
        //             if (enabled) {
        //                 this.startSpeechEdit();
        //             } else {
        //                 this.stopSpeechEdit();
        //             }
        //         }
        //     });
    }

    /** Flat node with expandable and level information */
    private _transformer(node: ItemNode, level: number) {
        const nodeFlat = new ItemNodeFlat();
        nodeFlat.expandable = !!node.children && node.children.length > 0;
        nodeFlat.id = node.id;
        nodeFlat.type = node.type;
        nodeFlat.tableId = node.tableId;
        nodeFlat.level = level;
        nodeFlat.image = node.image;
        nodeFlat.innerImage = node.innerImage;
        nodeFlat.looped = node.looped;
        nodeFlat.titleText = node.titleText;
        nodeFlat.edgeId = node.edgeId;
        nodeFlat.edgeText = node.edgeText;
        nodeFlat.nodeLanguage = node.nodeLanguage;
        nodeFlat.edgeLanguage = node.edgeLanguage;
        nodeFlat.locked = node.locked;
        nodeFlat.hasMath = node.hasMath;
        nodeFlat.mathLatex = node.mathLatex;
        flatNodeMap.set(nodeFlat, node);
        nestedNodeMap.set(node, nodeFlat);
        return nodeFlat;
    }

    closeExtra() {
        this.extraService.toggleExtraPanel(ExtraItem.CLOSE);
    }

    get data(): ItemNode[] { return this.dataSource.data; }

    treeControl = new FlatTreeControl<ItemNodeFlat>(
        node => node.level, node => node.expandable);
    treeFlattener = new MatTreeFlattener(
        this._transformer, node => node.level, node => node.expandable, node => node.children);
    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (nodeFlat: ItemNodeFlat) => nodeFlat.expandable;

    getId = (nodeFlat: ItemNodeFlat) => nodeFlat.id;

    getLevel = (nodeFlat: ItemNodeFlat) => nodeFlat.level;

    isExpandable = (nodeFlat: ItemNodeFlat) => nodeFlat.expandable;

    getChildren = (node: ItemNode): ItemNode[] | null => node.children;

    hasNoContent = (_: number, nodeFlat: ItemNodeFlat) => nodeFlat.titleText === '';

    getColor(node: ItemNodeFlat) {
        const colors = ['#b2ebf2', '#62c7ff', '#2fb5ff', '#15acff'];
        const level = this.getLevel(node) % 4;
        return colors[level];
    }

    getEllipsedText(text: string, maxLength: number) {
        let txt = text;
        if (text.length > maxLength) {
            txt = txt.substring(0, maxLength) + '...';
        }
        return txt;
    }

    getImage(nodeFlat: ItemNodeFlat) {
        let s = '';

        switch (nodeFlat.type) {
            case 'table':
                s = 'TABLE_PLACEHOLDER';
                break;
            case 'node':
                if (nodeFlat.innerImage && nodeFlat.innerImage.length > 10) { // avoid 'data:,' for not yet loaded images
                    s = nodeFlat.innerImage;
                }
                else {
                    s = '';
                }
                break;
            default:
                s = 'GENERIC_PLACEHOLDER';
                break;
        }

        return s;
    }

    setExpandedNodes(data: Array<ItemNode> | null, expTables: Map<string, boolean> | null) {
        if (data) {
            for (let i = 0; i < data.length; i++) {
                const itemNode = data[i];
                const itemNodeFlat = nestedNodeMap.get(itemNode);
                if (itemNodeFlat) {
                    if (itemNode.id.startsWith('t') && expTables) {
                        const tExp = expTables.get(itemNode.id);
                        if (tExp !== undefined) {
                            itemNode.expanded = tExp;
                            if (itemNode.expanded) {
                                this.treeControl.expand(itemNodeFlat);
                            } else {
                                this.treeControl.collapse(itemNodeFlat);
                            }
                        }
                    } else {
                        if (itemNode.expanded) {
                            this.treeControl.expand(itemNodeFlat);
                        } else {
                            this.treeControl.collapse(itemNodeFlat);
                        }
                        this.setExpandedNodes(itemNode.children, expTables)
                    }
                }
            }
        }
    }

    handleDragStart(event: any, node: ItemNodeFlat) {
        if (this.isMobileOrTabletDevice) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return;
        }

        if (this.isMobileOrTabletDevice) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return;
        }

        console.log('handleDragStart node=' + node.id);

        const type = this.findItemNodeType(node);
        if (event &&
            this.outlineService.canEdit &&
            type === MAPNODETYPE.NODE &&
            !this.isTalking &&
            !node.locked &&
            !this.editingNodeFlatId) {
            this.uiSelectItem(null);
            this.dragNodeFlat = node;
            this.treeControl.collapse(node);
            this.collapsedNodeDrag = node;

            //SET DRAG IMAGE DATA
            const cloneElement = document.getElementById(node.id);
            cloneElement?.classList.add("ghost");
            const clone = cloneElement?.cloneNode(true);

            const dragGhost = document.getElementById("drag-ghost-element");
            if (clone && dragGhost) {
                dragGhost.appendChild(clone);
                const XOffset = document.getElementById(node.id + "_nodeRef")?.offsetLeft;
                if (XOffset)
                    event.dataTransfer.setDragImage(dragGhost, XOffset + this.currentNodeWidth * 0.5, 25);
            }
        }
        else {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    handleDrag(event: any, node: ItemNodeFlat, nodeHtmlElement: HTMLElement) {

        if (this.isTalking) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return;
        }

        if (this.isMobileOrTabletDevice) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return;
        }

        //console.log('handleDrag node=' + node.id);

        if (node) {
            const htmlContainer: HTMLElement = <HTMLElement>this.outlineContainerHtmlElement;
            const containerBounding: DOMRect = htmlContainer.getBoundingClientRect();
            //console.log("CONTAINER = " + container.top + " " + container.left + " " + container.bottom + " " + container.right);
            //console.log("EVENT" + (<DragEvent>event).clientX + " " + (<DragEvent>event).clientY);

            const e: DragEvent = <DragEvent>event;

            const YPxOffset: number = 200;
            const scrollStep = 10;

            //check interno a rettangolo
            if (e.clientX < containerBounding.right &&
                e.clientX > containerBounding.left &&
                e.clientY > containerBounding.top &&
                e.clientY < containerBounding.bottom) {

                //check top e bottom area
                if (e.clientY <= containerBounding.top + YPxOffset) {
                    //console.log("SONO AREA TOP");
                    htmlContainer.scrollTop = htmlContainer.scrollTop - scrollStep;
                } else if (e.clientY >= containerBounding.bottom - YPxOffset) {
                    //console.log("SONO AREA BOTTOM");
                    htmlContainer.scrollTop = htmlContainer.scrollTop + scrollStep;
                }
            }
        }
    }

    handleDragOver(event: any, node: ItemNodeFlat) {
        if (this.isTalking) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return;
        }

        if (this.isMobileOrTabletDevice) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return;
        }

        //console.log('handleDragOver node=' + node.id);

        this.hoveringType = this.findItemNodeType(node);
        event.preventDefault();
        this.hoverNodeFlat = node;
        const n: ItemNode | undefined = flatNodeMap.get(node);
        this.hoverHasParent = (n && n.parent ? true : false);
        // Handle node expand
        if (node === this.dragNodeExpandOverNode) {
            if (this.dragNodeFlat !== node && !this.treeControl.isExpanded(node)) {
                if ((new Date().getTime() - this.dragNodeExpandOverTime) > this.dragNodeExpandOverWaitTimeMs) {
                    this.treeControl.expand(node);
                }
            }
        } else {
            this.dragNodeExpandOverNode = node;
            this.dragNodeExpandOverTime = new Date().getTime();
        }
    }

    handleDrop(event: any, dropItemNodeFlat: ItemNodeFlat, moveType: string) {
        if (this.isTalking) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return;
        }

        if (this.isMobileOrTabletDevice) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return;
        }

        console.log('handleDrop id=' + dropItemNodeFlat.id + ', pos=' + moveType);
        event.preventDefault();
        if (this.dragNodeFlat && dropItemNodeFlat && dropItemNodeFlat !== this.dragNodeFlat) {
            console.log('+++DragDrop: id=' + dropItemNodeFlat.id + ', moveType=' + moveType);
            const from = flatNodeMap.get(this.dragNodeFlat);
            const to = flatNodeMap.get(dropItemNodeFlat);
            this.moveItem(from, to, moveType);
        }
        if (this.collapsedNodeDrag) {
            this.treeControl.expand(this.collapsedNodeDrag);
        }
    }

    handleDragEnd(event: any) {
        if (this.isTalking) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return;
        }

        if (this.isMobileOrTabletDevice) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return;
        }

        // console.log('handleDragEnd');
        this.abortDrag();
    }

    private abortDrag() {
        this.hoverNodeFlat = undefined;
        this.dragNodeFlat = undefined;
        this.dragNodeExpandOverNode = null;
        this.dragNodeExpandOverTime = 0;

        const dragGhost = document.getElementById("drag-ghost-element");
        if (dragGhost)
            while (dragGhost.firstChild) {
                dragGhost.removeChild(dragGhost.firstChild);
            }
    }

    private moveItem(from: ItemNode | undefined, to: ItemNode | undefined, moveType: string) {
        if (from && to && moveType) {
            const newParent = this.findParent(to, moveType);
            this.moveSubTreeLeftOfFirstChild(from, to);
            this.manageEdge(from, newParent);
        } else {
            if (!from) console.log('+++from NOT DEFINED');
            if (!to) console.log('+++to NOT DEFINED');
            if (!moveType) console.log('+++moveType NOT DEFINED');
        }
    }

    private findParent(node: ItemNode | null, moveType: string): ItemNode | null { //, nodeFlat: ItemNodeFlat) {
        let newParent: ItemNode | null = null;
        if (node) {
            switch (moveType) {
                case 'parent':
                    newParent = this.findParent1Level(node);
                    newParent = this.findParent1Level(newParent);
                    break;
                case 'sibling':
                    newParent = this.findParent1Level(node);
                    break;
                case 'child':
                    newParent = node;
                    break;
            }
        }
        return newParent;
    }

    private findParent1Level(node: ItemNode | null): ItemNode | null {
        let parent: ItemNode | null = null;
        if (node) {
            if (node.parent) {
                parent = node.parent;
            }
        }
        return parent;
    }

    private moveSubTreeLeftOfFirstChild(from: ItemNode | undefined, to: ItemNode | undefined) {
        if (from && to) {
            const nodeIdList = new Array<string>();
            this.buildIdListFromNodeItem(from, nodeIdList);
            const sType = this.findItemNodeType(to);
            if (sType === MAPNODETYPE.NODE) {
                // Drop on a node
                if (to.children && to.children.length > 0) {
                    this.smService.moveNodesRelativeToNode(
                        nodeIdList,
                        -1,
                        0,
                        this.outlineService.getCleanId(to.children[0].id),
                        '',
                        ''
                    );
                } else {
                    this.smService.moveNodesRelativeToNode(
                        nodeIdList,
                        0,
                        1,
                        this.outlineService.getCleanId(to.id),
                        '',
                        ''
                    );
                }
            } else {
                // No drop on a table or on a cell
            }
        }
    }

    private manageEdge(from: ItemNode | null, newParent: ItemNode | null) {
        if (from) {
            if (from.parent && newParent) {
                // Reparent edge
                console.log(`+++Drop: reparent ${from.parent.id}->${from.id} to ${newParent.id}->${from.id}`)
                this.smService.reparentEdgeFromToNew(
                    this.outlineService.getCleanId(from.parent.id),
                    this.outlineService.getCleanId(from.id),
                    this.outlineService.getCleanId(newParent.id)
                );
            } else if (!from.parent && newParent) {
                // New egde
                this.smService.addNewEdgeFromTo(
                    this.outlineService.getCleanId(newParent.id),
                    this.outlineService.getCleanId(from.id)
                );
            } else if (from.parent && !newParent) {
                // Delete edge
                this.smService.deleteEdgeFromTo(
                    this.outlineService.getCleanId(from.parent.id),
                    this.outlineService.getCleanId(from.id)
                );
            } else if (!from.parent && !newParent) {
                // Nothing
            }
        }
    }

    findNextItemFlat(itemNodeFlat: ItemNodeFlat, dir: number): ItemNodeFlat | null {
        let nodeFlat = null;
        if (itemNodeFlat && dir !== 0) {
            nodeFlat = itemNodeFlat;
            const pos = this.treeControl.dataNodes.indexOf(nodeFlat);
            const newPos = pos + dir;
            if (newPos >= 0 && newPos < this.treeControl.dataNodes.length) {
                nodeFlat = this.treeControl.dataNodes[newPos];
            } else {
                nodeFlat = null;
            }
        }
        return nodeFlat;
    }

    //-----------------------------------------------------------------------------------------------------

    private updateToolbar() {
        const isSelected = (this.selectedNode !== undefined && this.selectedNode !== null);
        let selectedNodeHasChildren = false;
        if (this.selectedNode) {
            selectedNodeHasChildren = (isSelected && this.selectedNode.children && this.selectedNode.children.length > 0 ? true : false);
        }
        const type = this.findItemNodeType(this.selectedNode);
        switch (type) {
            case MAPNODETYPE.NONE:
                this.canAddSibling = true;
                this.canAddChild = false;
                this.canDelete = false;
                this.canEditItem = false;
                this.canMoveUp = false;
                this.canMoveDown = false;
                this.canMoveIn = false;
                this.canMoveOut = false;
                this.canSelectSubtree = false;
                break;
            case MAPNODETYPE.NODE:
                this.canAddSibling = this.outlineService.canEdit && isSelected;
                this.canAddChild = this.outlineService.canEdit && isSelected && this.selectedNode ? !this.selectedNode.locked : false;
                this.canDelete = this.outlineService.canEdit && isSelected;
                this.canEditItem = this.outlineService.canEdit && isSelected && this.selectedNode ? !this.selectedNode.locked : false;
                this.canMoveUp = this.outlineService.canEdit && isSelected && (this.findFirstSiblingInDir(this.selectedNode, -1) ? true : false) && this.selectedNode ? !this.selectedNode.locked : false;
                this.canMoveDown = this.outlineService.canEdit && isSelected && (this.findFirstSiblingInDir(this.selectedNode, 1) ? true : false) && this.selectedNode ? !this.selectedNode.locked : false;
                this.canMoveIn = this.outlineService.canEdit && isSelected && this.findParentNode(this.selectedNode) ? true : false;
                this.canMoveOut = this.outlineService.canEdit && isSelected && this.findFirstSiblingInDir(this.selectedNode, -1) ? true : false;
                this.canSelectSubtree = this.outlineService.canEdit && isSelected && selectedNodeHasChildren ? true : false;
                break;
            case MAPNODETYPE.TABLE:
                this.canAddSibling = false;
                this.canAddChild = false;
                this.canDelete = this.outlineService.canEdit && isSelected;
                this.canEditItem = false;
                this.canMoveUp = (this.outlineService.canEdit && isSelected && this.findFirstSiblingInDir(this.selectedNode, -1) ? true : false);
                this.canMoveDown = (this.outlineService.canEdit && isSelected && this.findFirstSiblingInDir(this.selectedNode, 1) ? true : false);
                this.canMoveIn = false;
                this.canMoveOut = false;
                this.canSelectSubtree = false;
                break;
            case MAPNODETYPE.CELL:
                this.canAddSibling = false;
                this.canAddChild = this.outlineService.canEdit && isSelected && this.selectedNode ? !this.selectedNode.locked : false;
                this.canDelete = this.outlineService.canEdit && isSelected && this.selectedNode ? !this.selectedNode.locked : false;
                this.canEditItem = this.outlineService.canEdit && isSelected && this.selectedNode ? !this.selectedNode.locked : false;
                this.canMoveUp = false;
                this.canMoveDown = false;
                this.canMoveIn = false;
                this.canMoveOut = false;
                this.canSelectSubtree = (this.outlineService.canEdit && isSelected && selectedNodeHasChildren ? true : false);
                break;
        }
        this.canQuickInput = this.outlineService.canEdit && this.selectedNode ? !this.selectedNode.locked : false;
        this.canArrange = this.outlineService.canEdit;
    }

    private findItemNodeType(itemNode: ItemNode | ItemNodeFlat | undefined): MAPNODETYPE {
        let res = MAPNODETYPE.NONE;
        if (itemNode) {
            if (itemNode.type === MAPNODETYPE.TABLE) {
                res = MAPNODETYPE.TABLE;
            } else if (itemNode.type === MAPNODETYPE.NODE && itemNode.tableId !== '') {
                res = MAPNODETYPE.CELL;
            } else {
                res = MAPNODETYPE.NODE;
            }
        }
        return res;
    }

    private isDeleteItemExpired() {
        const EXPIRE_MSEC = 1000;
        let expired = false;
        const now = new Date().getTime();
        expired = ((now - this.deleteTime) > EXPIRE_MSEC);
        return expired
    }


    uiSelectItem(flatNode: ItemNodeFlat | null, event: any = null) {
        if (event) {
            event.preventDefault();
        }

        if (flatNode) {
            this.abortDrag();
            // this.selectionTime = new Date().getTime();
            console.log('SELECTED: ' + flatNode.titleText);
            if (flatNode.id !== this.selectedNodeFlatId) {
                if (this.editTitleText) {
                    this.closeEditingNode(true);
                }
                this.selectedNodeFlatId = flatNode.id;
                this.selectedNodeFlat = this.treeControl.dataNodes.find(nodeFlat => nodeFlat.id === flatNode.id);
                if (this.editTitleText) {
                    this.editTitleText = (this.selectedNodeFlat ? this.selectedNodeFlat.titleText : '');
                }
                if (this.selectedNodeFlat) {
                    this.selectedNode = flatNodeMap.get(this.selectedNodeFlat);
                }
                if (this.outlineQuickInputState || this.needToEditSelectedItem) {
                    this.checkAndSkipNonEditableItems();
                    this.uiEditItem(200);
                }
                this.smService.selectMapElements([{ type: flatNode.type, id: this.outlineService.getCleanId(flatNode.id) }], false, "outline");
                this.updateToolbar();
            }
        }
    }

    uiToggleExpand(nodeFlat: ItemNodeFlat | undefined) {
        if (nodeFlat) {
            const expanded = this.treeControl.isExpanded(nodeFlat);
            this.smService.selectMapElement(nodeFlat.type, this.outlineService.getCleanId(nodeFlat.id), false, "outline");
            this.smService.setNodeState(
                expanded,
                this.outlineService.getCleanId(nodeFlat.id)
            );
            if (nodeFlat.id.startsWith('t') && this.expTables) {
                this.expTables.set(nodeFlat.id, expanded);
            }
            const node = flatNodeMap.get(nodeFlat);
            if (node) {
                node.expanded = expanded;
                if (expanded) {
                    this.treeControl.expand(nodeFlat);
                } else {
                    this.treeControl.collapse(nodeFlat);
                }
            }
            // this.smService.askOutline();
        }
    }

    uiAddSibling(text: string = '') {
        if (this.canAddSibling) {
            this.needToEditSelectedItem = true;
            const parentNode = (this.selectedNode ? this.selectedNode.parent : null);
            const refNode = (this.selectedNode ? this.selectedNode : null);
            const json = { titleText: text };
            const parentId = (parentNode ? parentNode.id : '');
            const refNodeId = (refNode ? refNode.id : '');
            this.smService.addChildNodeById(
                this.outlineService.getCleanId(parentId),
                json,
                this.outlineService.getCleanId(refNodeId),
                this.outlineService.getChildrenIds(parentNode)
            );
        }
    }

    uiAddChild(text: string = '') {
        if (this.canAddChild && this.selectedNode) {
            if (this.selectedNode) {
                this.needToEditSelectedItem = true;
                // const parentNode = (this.selectedNode ? this.selectedNode.parent : null);
                const refNodeId = (
                    this.selectedNode && this.selectedNode.children && this.selectedNode.children.length > 0
                        ? this.selectedNode.children[this.selectedNode.children.length - 1].id
                        : 'outlinefirstchild'
                );
                const json = { titleText: text };
                this.smService.addChildNodeById(
                    this.outlineService.getCleanId(this.selectedNode.id),
                    json,
                    this.outlineService.getCleanId(refNodeId),
                    this.outlineService.getChildrenIds(this.selectedNode)
                );
            }
        }
    }

    uiDeleteItem() {
        if (this.canDelete && this.selectedNodeFlat) {
            this.deleteTime = new Date().getTime();
            const type = this.findItemNodeType(this.selectedNode);
            switch (type) {
                case MAPNODETYPE.NONE:
                    break;
                case MAPNODETYPE.NODE:
                    const items = new Array<any>();
                    // Delete selected node
                    items.push({ type: 'node', id: this.outlineService.getCleanId(this.selectedNodeFlat.id) });
                    // Delete all subtree
                    this.findItemSubTree(items, this.selectedNode);
                    // Select and delete elements
                    this.smService.selectMapElements(items, false, "outline");
                    this.smService.deleteSelection(false);
                    break;
                case MAPNODETYPE.TABLE:
                    const table = new Array<any>();
                    if (this.selectedNode && this.selectedNode.children && this.selectedNode.children.length > 0) {
                        // Delete selected node
                        table.push({ type: 'node', id: this.outlineService.getCleanId(this.selectedNode.children[0].id) });
                        // Select and delete elements
                        this.smService.selectMapElements(table, false, "outline");
                        this.smService.deleteSelection(true);
                    }
                    break;
                case MAPNODETYPE.CELL:
                    const cells = new Array<any>();
                    // Delete selected node
                    cells.push({ type: 'node', id: this.outlineService.getCleanId(this.selectedNodeFlat.id) });
                    // Select and delete elements
                    this.smService.selectMapElements(cells, false, "outline");
                    this.smService.deleteSelection(false);
                    break;
            }
        }
    }

    private findItemSubTree(items: Array<any>, node: ItemNode | undefined) {
        if (this.outlineService.canEdit && node && node.children && node.children.length > 0) {
            for (let i = 0; i < node.children.length; i++) {
                const child = node.children[i];
                if (child) {
                    items.push({ type: 'node', id: this.outlineService.getCleanId(child.id) });
                    this.findItemSubTree(items, child);
                }
            }
        }
    }

    private setEditorFocus(delay: number = 100): Promise<void> {
        return new Promise((resolve) => {
            this.mapStateService.setFocusOnMap(false);
            if (this.elementEditFocusTimeout) {
                clearTimeout(this.elementEditFocusTimeout);
                this.elementEditFocusTimeout = null;
            }

            if (this.editingNodeFlatId) {
                this.elementEditFocusTimeout = setTimeout(() => {
                    const node = window.document.getElementById(this.editingNodeFlatId);
                    if (node) {
                        const editors = node.getElementsByClassName('edit-node');
                        if (editors && editors.length > 0) {
                            (editors[0] as HTMLTextAreaElement).focus();
                            setTimeout(() => {
                                return resolve();
                            }, 600);
                        }
                    }
                }, delay);
            }
            else {
                return resolve();
            }

        });
    }

    uiEditItem(delay: number = 0, event: any = null): Promise<void> {
        if (event) {
            event.preventDefault();
        }

        return new Promise((resolve) => {
            this.isEditOpen = (this.editingNodeFlatId ? true : false);
            if (!this.isTalking) {
                if (this.elementEditTimeout) {
                    clearTimeout(this.elementEditTimeout);
                    this.elementEditTimeout = null;
                }
                this.elementEditTimeout = setTimeout(() => {
                    if (this.canEditItem && this.selectedNodeFlat) {
                        this.smService.setEnableKeyPresses(false);
                        if (!this.editingNodeFlatId) {
                            this.editTitleText = (this.selectedNodeFlat ? this.selectedNodeFlat.titleText : '');
                        }
                        this.startEditTitleText = this.editTitleText;
                        this.editingNodeFlatId = this.selectedNodeFlatId;
                        this.needToEditSelectedItem = false;
                        this.setEditorFocus(0).then(() => {
                            return resolve();
                        });
                    } else {
                        return resolve();
                    }
                }, delay);
            } else {
                return resolve();
            }
        });
    }

    private resetEditItem() {
        this.editTitleText = '';
        this.editingNodeFlatId = '';
    }

    private confirmEdit(event: any) {
        if (this.selectedNodeFlat) {
            const isSpeechEnabled = this.speechEditEnabled;
            if (this.editTitleText.trim() !== this.selectedNodeFlat.titleText.trim()) {
                event.preventDefault();
                const data = { titleHtml: UiConstants.getDefaultHtmlForTitle(this.editTitleText, true), avoidSelectionUpdate: true };
                this.smService.editSelectedElement(data);
                this.smService.askOutline();
                if (!this.outlineQuickInputState) {
                    this.speechEditEnabled = false;
                }
                this.speechRecognitionService.DestroySpeechObject();
            }
            if (this.outlineQuickInputState) {
                this.selectNextFieldToEdit();
                if (isSpeechEnabled) {
                    this.startSpeechEdit();
                }
            } else {
                this.stopQuickInput();
                this.newNodeId = '';

            }
            this.mapStateService.autoRenameNewMap(this.editTitleText);
        }
    }

    private splitText(event: any, textInChild: boolean) {
        if (this.selectedNodeFlat && event && event.currentTarget) {
            const curPos = event.currentTarget.selectionStart;
            const textBefore = this.editTitleText.substring(0, curPos).trim();
            const textAfter = this.editTitleText.substring(curPos).trim();
            this.editTitleText = textBefore;
            this.selectedNodeFlat.titleText = textBefore;
            event.preventDefault();
            const data = { titleHtml: UiConstants.getDefaultHtmlForTitle(this.editTitleText, true), avoidSelectionUpdate: true };
            this.smService.editSelectedElement(data);
            if (textInChild) {
                this.uiAddChild(textAfter);
            } else {
                this.uiAddSibling(textAfter);
            }
        }
    }

    private cancelEdit(event: any) {
        event.preventDefault();
        this.stopQuickInput();
        this.speechEditEnabled = false;
        this.speechRecognitionService.DestroySpeechObject();
        if (this.selectedNodeFlatId === this.newNodeId && this.editTitleText === '') {
            this.uiDeleteItem();
            this.newNodeId = '';
        }
    }

    editorKeyCallback(event: any) {
        if (event &&
            this.outlineService.canEdit &&
            !this.isTalking &&
            this.selectedNodeFlat &&
            this.editingNodeFlatId) {
            switch (event.keyCode) {
                case 13:
                    if (!event.altKey && !event.shiftKey && !event.ctrlKey) {
                        // Enter: confirm edit
                        console.log('++++++++++editorKeyCallback: Enter');
                        this.confirmEdit(event);
                    } else if (!event.altKey && !event.shiftKey && event.ctrlKey) {
                        // Ctrl-Enter: split text and create a new sibling with tail
                        console.log('++++++++++editorKeyCallback: Ctrl-Enter');
                        this.splitText(event, false);
                    } else if (!event.altKey && event.shiftKey && event.ctrlKey) {
                        // Ctrl-Shift-Enter: split text and create a new child with tail
                        console.log('++++++++++editorKeyCallback: Ctrl-Shift-Enter');
                        this.splitText(event, true);
                    }
                    break;
                case 27:
                    if (!event.altKey && !event.shiftKey && !event.ctrlKey) {
                        // Esc: cancel edit
                        console.log('++++++++++editorKeyCallback: Esc');
                        this.cancelEdit(event);
                    }
                    break;
            }
        }
    }

    genericKeyCallback(event: any) {
        // console.log(`+++++genericKeyCallback OUTLINE: ${event.ctrlKey ? 'Ctrl' : ''} ${event.altKey ? 'Alt' : ''} ${event.shiftKey ? 'Shift' : ''} ${event.keyCode}`);
        if (event &&
            this.outlineService.canEdit &&
            !this.isTalking &&
            this.selectedNodeFlat) {
            switch (event.keyCode) {
                case 9:// Tab
                    if (!event.altKey && !event.shiftKey && !event.ctrlKey) {
                        console.log('++++++++++genericKeyCallback: Tab');
                        event.preventDefault();
                        this.isMoving = true;
                        this.uiMoveOut();
                        this.isMoving = false;
                        this.setEditorFocus();
                    } else if (!event.altKey && event.shiftKey && !event.ctrlKey) {
                        // Shift-Tab
                        console.log('++++++++++genericKeyCallback: Shift-Tab');
                        event.preventDefault();
                        this.isMoving = true;
                        this.uiMoveIn();
                        this.isMoving = false;
                        this.setEditorFocus();
                    }
                    break;
                // case 37:// Ctrl-arrow left
                //     if (!event.altKey && !event.shiftKey && event.ctrlKey) {
                //         console.log('++++++++++genericKeyCallback: Ctrl-arrow left');
                //         event.preventDefault();
                //         this.isMoving = true;
                //         this.uiMoveIn();
                //         this.isMoving = false;
                //         this.setEditorFocus();
                //     }
                //     break;
                case 38:// Ctrl-arrow up
                    if (!event.altKey && !event.shiftKey && event.ctrlKey) {
                        console.log('++++++++++genericKeyCallback: Ctrl-arrow up');
                        event.preventDefault();
                        this.isMoving = true;
                        this.uiMoveUp();
                        this.isMoving = false;
                        this.setEditorFocus();
                    }
                    break;
                // case 39:// Ctrl-arrow right
                //     if (!event.altKey && !event.shiftKey && event.ctrlKey) {
                //         console.log('++++++++++genericKeyCallback: Ctrl-arrow right');
                //         event.preventDefault();
                //         this.isMoving = true;
                //         this.uiMoveOut();
                //         this.isMoving = false;
                //         this.setEditorFocus();
                //     }
                //     break;
                case 40:// Ctrl-arrow down
                    if (!event.altKey && !event.shiftKey && event.ctrlKey) {
                        console.log('++++++++++genericKeyCallback: Ctrl-arrow down');
                        event.preventDefault();
                        this.isMoving = true;
                        this.uiMoveDown();
                        this.isMoving = false;
                        this.setEditorFocus();
                    }
                    break;
                case 46: //CANC
                    if (!event.altKey && !event.shiftKey && !event.ctrlKey) {
                        console.log('++++++++++genericKeyCallback: CANC down');
                        if (this.editingNodeFlatId === '') {
                            event.preventDefault();
                            this.uiDeleteItem();
                        }
                    }
                    break;
            }
        }
    }

    private getFlatNodeAtIndex(idx: number): ItemNodeFlat | undefined {
        if (idx >= 0 && idx < this.treeControl.dataNodes.length) {
            return this.treeControl.dataNodes[idx];
        } else {
            return undefined;
        }
    }

    private ensureVisible(node: ItemNode | undefined) {
        while (node) {
            const nodeFlat = nestedNodeMap.get(node);
            if (nodeFlat) {
                node.expanded = true;
                this.treeControl.expand(nodeFlat);
                if (nodeFlat.id.startsWith('t')) {
                    this.expTables?.set(nodeFlat.id, true);
                }
                node = (node.parent ? node.parent : undefined);
            }
        }
    }

    private selectNextFieldToEdit() {
        if (this.selectedNodeFlat) {
            let idx = this.treeControl.dataNodes.indexOf(this.selectedNodeFlat);
            if (idx >= 0) {
                const startNodeFlat = this.selectedNodeFlat;
                const startNode = (this.selectedNode?.tableId === '' ? this.selectedNode : null);
                idx++;
                let nextNode = this.getFlatNodeAtIndex(idx);
                let type = this.findItemNodeType(nextNode);
                //ciclo che ignora i nodi da saltare
                while (nextNode &&
                    (type === MAPNODETYPE.TABLE ||
                        !nextNode.id.endsWith('_0') ||
                        nextNode.locked)) {
                    idx++;
                    nextNode = this.getFlatNodeAtIndex(idx);
                    type = this.findItemNodeType(nextNode);
                }
                if (nextNode) {
                    this.needToEditSelectedItem = true;
                    this.selectedNodeFlat = this.treeControl.dataNodes[idx];
                    this.selectedNode = flatNodeMap.get(this.selectedNodeFlat);
                    this.ensureVisible(this.selectedNode);
                    this.uiSelectItem(this.selectedNodeFlat);
                } else {
                    if (startNode) {
                        const parent = (startNode.parent ? startNode.parent : startNode);
                        this.addNodeFromOutline(
                            this.outlineService.getCleanId(parent.id),
                            this.outlineService.getCleanId(startNode.id),
                            startNode.level
                        );
                    } else {
                        // startNode was table cell
                        this.smService.resetSelection();
                        const p = this.smService.getPointerPos();
                        p.x += 150;
                        this.smService.setPointerPos(p.x, p.y);
                        this.smService.addNodeAndSelectIfNoSelection(null);
                    }
                }
            } else {
                this.stopQuickInput();
            }
        } else {
            this.stopQuickInput();
        }
    }


    private stopQuickInput() {
        this.speechEditEnabled = false;
        this.outlineQuickInputState = false;
        this.resetEditItem();
        this.smService.setEnableKeyPresses(true);
    }

    private addNodeFromOutline(parentId: string, refNodeId: string, level: number) {
        const parentNode = this.smService.getNodeById(parentId);
        if (parentNode) {
            if (this.selectedNodeFlat) {
                let stageX = 0;
                let stageY = 0;
                if (level === 1) {
                    // First level: add child down
                    stageX = parentNode.geometry.x;
                    stageY = parentNode.geometry.y + (parentNode.geometry.height / 2) + 40 + 40;
                } else {
                    // Inner level: add child right
                    if (this.selectedNode && this.selectedNode.parent) {
                        const refNode = this.smService.getNodeById(refNodeId);
                        stageX = refNode.geometry.x + (refNode.geometry.width / 2) + 60 + 40;
                        stageY = refNode.geometry.y;
                    }
                }
                const clientP = this.smService.stageToGlobal(stageX, stageY);
                const newNode = this.smService.addChildNode(parentNode, clientP.x, clientP.y);
                this.newNodeId = newNode.id + '_0';
                this.smService.selectMapElements([{ type: 'node', id: newNode.id }], false, "outline");
            }
        }
    }

    public uiMoveUp() {
        console.log('MOVE UP');
        if (this.canMoveUp && this.selectedNode) {
            const sibling = this.findFirstSiblingInDir(this.selectedNode, -1);
            if (sibling) {
                const movingNodeIdList = new Array<string>();
                const siblingNodeIdList = new Array<string>();
                this.buildIdListFromNodeItem(this.selectedNode, movingNodeIdList);
                this.buildIdListFromNodeItem(sibling, siblingNodeIdList);
                const sType = this.findItemNodeType(sibling);
                if (sType === MAPNODETYPE.TABLE && sibling.children && sibling.children.length > 0) {
                    this.smService.moveNodesRelativeToNode(
                        movingNodeIdList,
                        -1,
                        0,
                        this.outlineService.getCleanId(sibling.children[0].id),
                        '',
                        ''
                    );
                } else {
                    this.smService.swapSubTrees(
                        movingNodeIdList,
                        siblingNodeIdList
                    );
                }
            }
        }
    }

    public uiMoveDown() {
        console.log('MOVE DOWN');
        if (this.canMoveDown && this.selectedNode) {
            const sibling = this.findFirstSiblingInDir(this.selectedNode, 1);
            if (sibling) {
                const movingNodeIdList = new Array<string>();
                const siblingNodeIdList = new Array<string>();
                this.buildIdListFromNodeItem(this.selectedNode, movingNodeIdList);
                this.buildIdListFromNodeItem(sibling, siblingNodeIdList);
                const sType = this.findItemNodeType(sibling);
                if (sType === MAPNODETYPE.TABLE && sibling.children && sibling.children.length > 0) {
                    this.smService.moveNodesRelativeToNode(
                        movingNodeIdList,
                        1,
                        0,
                        this.outlineService.getCleanId(sibling.children[0].id),
                        '',
                        ''
                    );
                } else {
                    this.smService.swapSubTrees(
                        movingNodeIdList,
                        siblingNodeIdList
                    );
                }
            }
        }
    }

    public uiMoveIn() {
        console.log('MOVE IN');
        if (this.canMoveIn && this.selectedNode && this.selectedNode.level > 1) {
            if (this.editTitleText) {
                const data = { titleHtml: UiConstants.getDefaultHtmlForTitle(this.editTitleText, true), avoidSelectionUpdate: true };
                this.smService.editSelectedElement(data);
            }
            const parentNode = this.findParentNode(this.selectedNode);
            if (parentNode) {
                const grandParentNode = parentNode.parent;
                if (grandParentNode) {
                    // Move subtree down new parent
                    const nodeIdList = new Array<string>();
                    this.buildIdListFromNodeItem(this.selectedNode, nodeIdList);
                    this.smService.moveNodesRelativeToNode(
                        nodeIdList,
                        0,
                        1,
                        this.outlineService.getCleanId(grandParentNode.id),
                        '',
                        ''
                    );
                    // Reparent Edge parent->node ==> grandparent->node
                    this.smService.reparentEdgeFromToNew(
                        this.outlineService.getCleanId(parentNode.id),
                        this.outlineService.getCleanId(this.selectedNodeFlatId),
                        this.outlineService.getCleanId(grandParentNode.id)
                    );
                } else {
                    // Delete edge parent->node
                    this.smService.deleteEdgeFromTo(
                        this.outlineService.getCleanId(parentNode.id),
                        this.outlineService.getCleanId(this.selectedNodeFlatId)
                    );
                }
            } else {
                // NONE: Already al level 1
            }
        }
    }

    public uiMoveOut() {
        console.log('MOVE OUT');
        if (this.canMoveOut && this.selectedNode) {
            if (this.editTitleText) {
                const data = { titleHtml: UiConstants.getDefaultHtmlForTitle(this.editTitleText, true), avoidSelectionUpdate: true };
                this.smService.editSelectedElement(data);
            }
            const newParentNode = this.findFirstSiblingInDir(this.selectedNode, -1);
            if (this.selectedNode.parent && newParentNode) {
                // Move subtree down new parent
                const nodeIdList = new Array<string>();
                this.buildIdListFromNodeItem(this.selectedNode, nodeIdList);
                this.smService.moveNodesRelativeToNode(
                    nodeIdList,
                    0,
                    1,
                    this.outlineService.getCleanId(newParentNode.id),
                    '',
                    ''
                );
                // Reparent edge
                this.smService.reparentEdgeFromToNew(
                    this.outlineService.getCleanId(this.selectedNode.parent.id),
                    this.outlineService.getCleanId(this.selectedNode.id),
                    this.outlineService.getCleanId(newParentNode.id)
                );
            } else if (!this.selectedNode.parent && newParentNode) {
                // Add edge (ex level 1)
                this.smService.addNewEdgeFromTo(
                    this.outlineService.getCleanId(newParentNode.id),
                    this.outlineService.getCleanId(this.selectedNode.id)
                );
            }
        }
    }

    public uiSelectSubtree() {
        if (this.canSelectSubtree && this.selectedNode) {
            const items = new Array<any>();
            items.push({ type: 'node', id: this.outlineService.getCleanId(this.selectedNode.id) });
            this.findItemSubTree(items, this.selectedNode);
            this.smService.selectMapElements(items, false, "outline");
        }
    }

    public uiToggleQuickInput() {
        if (this.canQuickInput) {
            this.outlineQuickInputState = !this.outlineQuickInputState;
            if (this.outlineQuickInputState) {
                if (this.treeControl.dataNodes.length > 0) {
                    // Edit existing node
                    this.checkAndSkipNonEditableItems();
                    this.uiEditItem(0);
                } else {
                    // Create first node
                    this.smService.addNodeAndSelectIfNoSelection(null);
                }
            } else {
                this.stopQuickInput();
            }
        }
    }

    public uiToggleViewNodeImage() {
        this.outlineViewNodeImageState = !this.outlineViewNodeImageState;
        this.outlineViewNodeTooltip = (
            this.outlineViewNodeImageState ?
                this.translateService.instant('OUTLINE_NODE_VIEW_IMAGE_HIDE') :
                this.translateService.instant('OUTLINE_NODE_VIEW_IMAGE_SHOW')
        );
    }

    private checkAndSkipNonEditableItems() {
        const type = this.findItemNodeType(this.selectedNode);
        if (type === MAPNODETYPE.TABLE) {
            this.selectNextFieldToEdit();
        }
    }

    public uiArrangeMap() {
        if (this.canArrange) {
            this.arrangeService.arrangeMap();
        }
    }

    private findParentNode(node: ItemNode | undefined): ItemNode | null {
        return (node ? node.parent : null);
    }

    private buildIdListFromNodeItem(itemNode: ItemNode, nodeIdList: Array<string>) {
        if (itemNode) {
            const type = this.findItemNodeType(itemNode);
            if (type === MAPNODETYPE.NODE || type === MAPNODETYPE.CELL) {
                nodeIdList.push(this.outlineService.getCleanId(itemNode.id));
                if (itemNode.children) {
                    for (let i = 0; i < itemNode.children.length; i++) {
                        const childNode = itemNode.children[i];
                        this.buildIdListFromNodeItem(childNode, nodeIdList);
                    }
                }
            } else if (type === MAPNODETYPE.TABLE) {
                if (itemNode.children) {
                    for (let i = 0; i < itemNode.children.length; i++) {
                        const childNode = itemNode.children[i];
                        this.buildIdListFromNodeItem(childNode, nodeIdList);
                    }
                }
            }
        }
        return nodeIdList;
    }

    private findFirstSiblingInDir(node: ItemNode | undefined, dir: number): ItemNode | null {
        let newNode: ItemNode | null = null;
        if (node) {
            const nodeFlat = nestedNodeMap.get(node);
            if (nodeFlat && dir !== 0) {
                let i = this.treeControl.dataNodes.indexOf(nodeFlat) + dir;
                let exit = false;
                while (!exit && i >= 0 && i < this.treeControl.dataNodes.length) {
                    const currentNodeFlat = this.treeControl.dataNodes[i];
                    if (nodeFlat.level === currentNodeFlat.level) {
                        exit = true;
                        const n = flatNodeMap.get(currentNodeFlat);
                        newNode = (n ? n : null);
                    } else if (currentNodeFlat.level < nodeFlat.level) {
                        exit = true;
                    } else {
                        i += dir;
                    }
                }
            }
        }
        return newNode;
    }

    //RESIZE CELLA
    public getNodeStyleWidth(): any {
        return { 'width.px': this.currentNodeWidth, 'max-width.px': + this.nodeMaxWidth, 'min-width.px': + this.nodeMinWidth }
    }
    currentNodeWidth: number = 443; //STANDARD WIDTH
    nodeMinWidth: number = 300;
    nodeMaxWidth: number = 600;

    public validate(event: ResizeEvent): boolean {
        return true;
    }

    public onResizeNodeStart(event: ResizeEvent, t: HTMLElement) {
    }

    public onResizingNode(event: ResizeEvent, t: HTMLElement) {
    }

    public onResizeNodeEnd(event: ResizeEvent, t: HTMLElement) {
        //console.log("RESIZE NODE END " + t.offsetWidth + " " + t.clientWidth);
        //console.log("RESIZE NODE END EVENT" + event.edges.right + " " + event.rectangle.width);

        if (t && event && event.edges.right) {
            this.currentNodeWidth = clamp((t.clientWidth + (event.edges.right as number)), this.nodeMinWidth, this.nodeMaxWidth);

            const usermail = this.authService.getUserEmail();
            if (usermail != '') {
                const mapId = this.mapStateService.id;
                this.userPreferencesService.saveCookie(usermail + "_" + mapId + "_outline_node_width", this.currentNodeWidth.toString());
            }

            //console.log("RESIZED ELEMENT " + this.currentNodeWidth);
        }
    }

    //BUCO DI DROP
    public getNodeDropHoleDimension(t: string | undefined) {
        if (t) {
            const e = <HTMLElement>document.getElementById(t);
            return { 'height.px': e.clientHeight, 'flex-grow': 0, 'flex-shrink': 0, 'width.px': this.currentNodeWidth }
        }
        else {
            return { 'height.px': 50, 'flex-grow': 0, 'flex-shrink': 0, 'width': '100%' };
        }

    }

    public getNodeDropShadow(t: string | undefined) {
        if (t) {
            const e = <HTMLElement>document.getElementById(t);
            return { 'height.px': e.clientHeight, 'width.px': this.currentNodeWidth, 'margin-left': '25px', 'background-color': '#eaeeef', 'border-top-right-radius.px': 5, 'border-bottom-right-radius.px': 5 }
        }
        else {
            return { 'height.px': 50, 'width.px': this.currentNodeWidth, 'margin-left': '25px', 'background-color': '#eaeeef', 'border-top-right-radius.px': 5, 'border-bottom-right-radius.px': 5 };
        }

    }

    private bringElementIntoScrollView(elementId: string | undefined, delay: number = 0): Promise<void> {
        return new Promise((resolve) => {
            if (elementId !== '' && elementId !== undefined) {
                if (this.elementInScrollViewTimeout) {
                    clearTimeout(this.elementInScrollViewTimeout);
                    this.elementInScrollViewTimeout = null;
                }
                this.elementInScrollViewTimeout = setTimeout(() => {
                    const e = <HTMLElement>document.getElementById(elementId + "_nodeRef");
                    if (e) {
                        if (this.isAfterUpdate) {
                            e.scrollIntoView({ behavior: 'auto', block: 'center' });
                        } else {
                            e.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        }
                        setTimeout(() => {
                            return resolve();
                        }, 10);
                    }
                    else {
                        return resolve();
                    }
                }, delay);
            }
        });
    }

    disableMapFocus() {
        //this.mapStateService.setEditingState(true);
        this.mapStateService.setFocusOnMap(false);
        // this.smService.setEnableKeyPresses(false);
        //this.quickEditService.onCanEdit.emit(false);
        //this.mapStateService.setFocusOnMap(false);
    }



    //TTS REGION
    changeSpeechRate(event: any) {
        this.rateValue = event.target.value;
        this.ttsService.voiceRate = event.target.value;
        const usermail = this.authService.getUserEmail();
        if (usermail != '') {
            this.userPreferencesService.saveReadSpeedCookie(usermail, this._currLanguage, this.rateValue);
        }
    }

    private setLanguage(currLanguage: string) {
        this._currLanguage = currLanguage;
        const spellLanguage = currLanguage.replace('-', '_');
        (window as any).WEBSPELLCHECKER_CONFIG.lang = spellLanguage;
        localStorage.setItem('wsc_lang', 'string<$>' + spellLanguage);
        if (this.languages) {
            const language = UiConstants.findLanguage(this._currLanguage, this.languages);
            if (language && language.icon) {
                this.selectedLangIcon = language.icon;
                this.rateValue = this.getSavedReadSpeedByCurrentLanguage();
                this.ttsService.voiceRate = this.rateValue;
            }
        }
    }

    private getSavedReadSpeedByCurrentLanguage(): number {
        const usermail = this.authService.getUserEmail();
        if (usermail != '') {
            const filter: Array<string> = [usermail, this._currLanguage];
            const savedReadSpeed = this.userPreferencesService.getReadSpeedCookie(filter);
            if (savedReadSpeed > 0) {
                return savedReadSpeed;
            } else {
                return 1;
            }
        }

        return 1;
    }

    changeLanguage(event: any) {
        this.setLanguage(event.currentTarget.id);
        this.smService.setSelectionLanguage(this._currLanguage);
    }

    public setTalkingNode(node: ItemNodeFlat | null) {
        this.talkingNodeFlat = node ? node : undefined;
        this.talkingNodeFlatId = node ? node.id : '';

        this.bringElementIntoScrollView(this.talkingNodeFlat?.id, 200);

        this.updateLanguageFlag();
    }

    public TTSRead() {
        this.stopQuickInput();
        this.ttsService.stop();
        //Se ho selezionato qualcosa parto da quello selezionato a leggere
        if (this.selectedNodeFlat) {
            this.setTalkingNode(this.selectedNodeFlat);
            this.wasTalkingEdge = false;
            this.talkOutlineElement(true);
        } else { //se non ho nessuna selezione parto dal primo
            if (this.treeControl.dataNodes.length > 0) {
                const first = this.treeControl.dataNodes[0];
                this.wasTalkingEdge = false;
                this.setTalkingNode(first);
                this.talkOutlineElement(true);
            }
        }
    }

    public TTSStop() {
        this.setTalkingNode(null);
        this.ttsService.stop();
    }

    private isNodeToBeTalked(itemFlat: ItemNodeFlat | undefined) {
        const node = itemFlat;
        return (node && (this.findItemNodeType(node) !== MAPNODETYPE.TABLE && (node.titleText.trim() !== '' || node.edgeText.trim() !== '' || node.hasMath)));
    }

    private getTextToTalk(itemFlat: ItemNodeFlat, type: TalkTextType = TalkTextType.EDGE_NODE): string {
        switch (type) {
            case TalkTextType.EDGE_NODE:
                return (itemFlat.edgeText + (itemFlat.edgeText ? '. ' : '') + itemFlat.titleText).trim();
            case TalkTextType.EDGE:
                return (itemFlat.edgeText).trim();
            case TalkTextType.NODE:
                return (itemFlat.titleText).trim();
        }
    }

    private talkOutlineElement(talkCurrentNode: boolean = false) {
        if (this.talkingNodeFlat) {
            let idx = this.treeControl.dataNodes.indexOf(this.talkingNodeFlat);
            if (idx >= 0) {
                if (!talkCurrentNode)
                    idx++;
                let nextNode = this.getFlatNodeAtIndex(idx);

                while (nextNode && !this.isNodeToBeTalked(nextNode)) {
                    idx++;
                    nextNode = this.getFlatNodeAtIndex(idx);
                }

                if (nextNode) {
                    const talkingNode = flatNodeMap.get(nextNode);
                    this.ensureVisible(talkingNode);
                    //check di cosa deve essere parlato (arco+nodo o solo arco o solo nodo)
                    let txt = '';
                    if (nextNode.edgeText == '') {
                        txt = this.getTextToTalk(nextNode, TalkTextType.NODE);
                        this.wasTalkingEdge = false;
                    } else {
                        if (nextNode.edgeLanguage !== nextNode.nodeLanguage) {
                            if (!this.wasTalkingEdge) {
                                txt = this.getTextToTalk(nextNode, TalkTextType.EDGE);
                                this.wasTalkingEdge = true;
                            } else {
                                txt = this.getTextToTalk(nextNode, TalkTextType.NODE);
                                this.wasTalkingEdge = false;
                            }
                        } else {
                            txt = this.getTextToTalk(nextNode, TalkTextType.EDGE_NODE);
                            this.wasTalkingEdge = false;
                        }
                    }

                    if (txt !== '' || nextNode.hasMath) {
                        this.setTalkingNode(nextNode);
                        txt = txt + ' ' + this.getMathLatexText(nextNode);
                        this.ttsService.speak(txt, this._currLanguage, true);
                    }
                    else {
                        this.setTalkingNode(null);
                    }
                } else {
                    this.setTalkingNode(null);
                }
            } else {
                this.setTalkingNode(null);
            }
        }
    }

    private getMathLatexText(node: ItemNodeFlat): string {
        let txt = '';
        if (node.hasMath && (this._currLanguage.includes('it') || this._currLanguage.includes('en'))) {
            txt = this.mathService.readLatex(node.mathLatex, this._currLanguage);
        }

        return txt;
    }

    private updateLanguageFlag() {
        if (this.talkingNodeFlat) {
            if (this.talkingNodeFlatId !== '') {
                const nLanguage = this.wasTalkingEdge ? this.talkingNodeFlat.edgeLanguage : this.talkingNodeFlat.nodeLanguage;
                if (nLanguage !== '') {
                    this.setLanguage(nLanguage);
                } else {
                    this.setLanguage('');
                }
            } else {
                this.setLanguage('');
            }
        } else {
            if (this.selectedNodeFlat) {
                const nLanguage = this.selectedNodeFlat.nodeLanguage;
                if (nLanguage !== '') {
                    this.setLanguage(nLanguage);
                } else {
                    this.setLanguage('');
                }
            } else {
                this.setLanguage('');
            }
        }
    }

    speedGotFocus() {
    }

    speedLostFocus() {
        this.checkSpeedValue();
    }

    checkSpeedValue() {
        if (this.rateValue < 0.5) {
            this.rateValue = 0.5;
        }
        if (this.rateValue > 1.5) {
            this.rateValue = 1.5;
        }
    }

    closeEditingNode(keepResults: boolean) {
        if (keepResults) {
            const data = { titleHtml: UiConstants.getDefaultHtmlForTitle(this.editTitleText, true), avoidSelectionUpdate: true };
            this.smService.editSelectedElement(data);
            this.smService.askOutline();
        }
        this.editingNodeFlatId = '';
        this.editTitleText = '';
    }

    speechEdit() {
        this.speechEditEnabled = !this.speechEditEnabled;
        if (this.speechEditEnabled) {
            this.uiEditItem(0).then(() => {
                this.startSpeechEdit();
            });
        } else {
            this.stopSpeechEdit();
        }
    }

    private startSpeechEdit() {
        if (this.speechRecordSubscription) { this.speechRecordSubscription.unsubscribe(); }
        this.speechRecordSubscription = this.speechRecognitionService.record(this._currLanguage)
            .subscribe({
                next: (srr: SpeechRecognitionResult) => {
                    const text: string = srr.transcript;
                    if (srr.isFinal) {
                        // End speech recognition
                        if (text.toLowerCase() === 'ok' || text.toLowerCase() === 'stop') {
                            console.log('speech stopped!');
                        } else {
                            console.log('speech complete!');
                            this.speechRecognitionService.DestroySpeechObject();
                        }
                        console.log('+++++SPEECH RECOG: ' + text);
                        if (this.outlineQuickInputState) {
                            this.continueSpeechEdit();
                        } else {
                            if (!this.isEditOpen) {
                                this.closeEditingNode(true);
                            }
                            this.speechEditEnabled = false;
                        }
                    } else {
                        // Partial speech recognition
                        this.editTitleText = this.startEditTitleText + ' ' + text;
                    }
                },
                // error
                error: (err) => {
                    if (err.error === 'no-speech') {
                        console.log('SpeechEdit error: no-speech');
                        // this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.DEEP);
                    } else {
                        console.log('SpeechEdit: ' + JSON.stringify(err) + err.error);
                    }

                    if (this.outlineQuickInputState) {
                        this.continueSpeechEdit();
                    } else {
                        if (!this.isEditOpen) {
                            this.closeEditingNode(true);
                        }
                        this.speechEditEnabled = false;
                    }
                },
                // completion
                complete: () => {
                    console.log('SpeechEdit: complete');
                    if (this.outlineQuickInputState) {
                        this.continueSpeechEdit();
                    } else {
                        if (!this.isEditOpen) {
                            this.closeEditingNode(true);
                        }
                        this.speechEditEnabled = false;
                    }
                }
            });
        // }
    }

    private continueSpeechEdit() {
        setTimeout(() => {
            this.startEditTitleText = this.editTitleText;
            this.startSpeechEdit();
        }, 1);
    }

    private stopSpeechEdit() {
        console.log('stopSpeechEdit outline');
        this.speechRecognitionService.DestroySpeechObject();
        this.closeEditingNode(true);
    }

    private IsAfterUpdateStart() {
        this.isAfterUpdate = true;
        setTimeout(() => {
            this.isAfterUpdate = false;
        }, 1000);
    }
    //TTS REGION END

    public showMathButton(node: ItemNodeFlat) {
        return this.authService.isMathEnabled() && !this.isTalking && !this.outlineQuickInputState && node.hasMath;
    }

    public openMathEditor(node: ItemNodeFlat) {
        this.uiSelectItem(node);
        this.mathService.editMath();
    }

    public isImageVisible(node: ItemNodeFlat): boolean {
        let res = true;
        if (this.showMathButton(node)) {
            if (this.editingNodeFlatId || !this.outlineViewNodeImageState || this.getImage(node) === '') {
                res = false;
            }
        } else {
            if (!this.outlineViewNodeImageState) {
                res = false;
            }
        }
        return res;
    }

}
