import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SmEventBroker } from '../../shared/sm-event-broker';
import { SmeService } from '../../core/sme/sme.service';
import { SmService } from 'supermappe-core';
import { MapStateService } from '../../shared/map-state.service';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { MathService } from 'src/app/shared/commands/math.service';

export enum MAPNODETYPE {
    NONE = 'none',
    NODE = 'node',
    CELL = 'cell',
    EDGE = 'edge',
    TABLE = 'table'
}

export class ItemNode {
    public id: string = '';
    public type: string = '';
    public tableId: string = '';
    public level: number = 0;
    public expandable: boolean = false;
    public parent: ItemNode | null = null;
    public children: Array<ItemNode> | null = null;
    public titleText: string = '';
    public image: string = ''; // DATA URL
    public innerImage: string = ''; // DATA URL
    public looped: boolean = false;
    public expanded: boolean = false;
    public edgeId: string = '';
    public edgeText: string = '';
    public nodeLanguage: string = '';
    public edgeLanguage: string = '';
    public locked: boolean = false;
    public hasMath: boolean = false;
    public mathLatex: string = '';
}

export class ItemNodeFlat {
    public id: string = '';
    public type: string = '';
    public tableId: string = '';
    public level: number = 0;
    public expandable: boolean = true;
    public titleText: string = '';
    public image: string = ''; // DATA URL
    public innerImage: string = ''; // DATA URL
    public looped: boolean = false;
    public edgeId: string = '';
    public edgeText: string = '';
    public nodeLanguage: string = '';
    public edgeLanguage: string = '';
    public locked: boolean = false;
    public hasMath: boolean = false;
    public mathLatex: string = '';
}

@Injectable({
    providedIn: 'root'
})
export class OutlineService implements OnDestroy {

    public canEdit = false;
    public outlineOpened = false;
    // findMapSharedInfoSubscription: Subscription | undefined;
    getShareDataSubscription: Subscription | undefined;

    public onOutlineUpdated = new BehaviorSubject<ItemNode[]>([]);
    public onOutlineSelectionUpdated = new BehaviorSubject<any>(null);
    public onNodeStateUpdated = new BehaviorSubject<any>(null);

    private outlineUpdatedSubscription: Subscription | undefined;
    private selectionDataSubscription: Subscription | undefined;
    private nodeStateSubscription: Subscription | undefined;

    constructor(
        private smEventBroker: SmEventBroker,
        private smeService: SmeService,
        private smService: SmService,
        private mapStateService: MapStateService,
        private authenticationService: AuthenticationService,
        private mathService: MathService
    ) {
        // Listen to outline updates
        this.outlineUpdatedSubscription = this.smEventBroker?.onOutlineUpdatedData?.subscribe((outline: any) => {
            this.outlineUpdated(outline)
        });
        this.selectionDataSubscription = this.smEventBroker?.selectionData?.subscribe((_selectionData: any) => {
            this.selectionUpdated(_selectionData)
        });
        this.nodeStateSubscription = this.smEventBroker?.onNodeStateData?.subscribe((data: any) => {
            this.nodeStateUpdated(data)
        });
    }

    ngOnDestroy(): void {
        if (this.outlineUpdatedSubscription) this.outlineUpdatedSubscription.unsubscribe();
        if (this.selectionDataSubscription) this.selectionDataSubscription.unsubscribe();
        if (this.nodeStateSubscription) this.nodeStateSubscription.unsubscribe();
    }

    checkOwner() {
        const email = (this.authenticationService.getUserEmail());
        const firebaseUserId = this.authenticationService.credentials?.firebaseUserId + '';
        const mapId = this.mapStateService.id;
        this.getShareDataSubscription = this.smeService.getShareData(email, firebaseUserId, mapId).subscribe((_data: any) => {
            if (_data && _data.shareData) {
                this.canEdit = _data.shareData.isMine;
            } else {
                this.canEdit = false;
            }
        });
    }

    private selectionUpdated(_selectionData: any) {
        this.onOutlineSelectionUpdated.next(_selectionData);
    }

    private nodeStateUpdated(data: any) {
        this.onNodeStateUpdated.next(data);
    }

    private assignItemId(origId: string, idExt: Map<string, number>): string {
        let id = '';
        const ext = idExt.get(origId);
        if (ext === undefined) {
            id = origId + '_0';
            idExt.set(origId, 0);
        } else {
            id = origId + '_' + (ext + 1);
            idExt.set(origId, ext + 1);
        }
        return id;
    }

    public getCleanId(outlineId: string): string {
        let id = outlineId;
        if (outlineId) {
            const p = outlineId.indexOf('_');
            if (p >= 0) {
                id = outlineId.substring(0, 4);
            }
        }
        return id;
    }

    public getChildrenIds(parent: ItemNode | null): Array<string> {
        const res = new Array<string>();
        if (parent && parent.children) {
            for (let i = 0; i < parent.children.length; i++) {
                const child = parent.children[i];
                const cleanId = this.getCleanId(child.id);
                res.push(cleanId);
            }
        }
        return res;
    }

    private outlineUpdated(outline: any) {
        console.log('OUTLINE UPDATED');
        const itemNodes = new Array<ItemNode>();
        const tableIdMap = new Map<string, ItemNode>();
        const idExt = new Map<string, number>();
        if (outline && outline.readOrderInfo) {
            const parents = new Map<number, ItemNode | null>();
            // let lastEdge = null;
            let previousEdge: ItemNode | null = null;
            for (let i = 0; i < outline.readOrderInfo.length; i++) {
                const item = outline.readOrderInfo[i];
                if (item.type === MAPNODETYPE.NODE || item.titleText.trim() !== '') {
                    const node: any = this.smService.sm.map.nodes[item.id];
                    const edge: any = this.smService.sm.map.edges[item.id];
                    if (node || edge) {
                        if (edge || (node.nodeTable && node.nodeTable.tableId === '')) {
                            let newItemNode;
                            if (!previousEdge) {
                                newItemNode = new ItemNode();
                            } else {
                                newItemNode = previousEdge;
                            }
                            if (item.type === MAPNODETYPE.NODE) {
                                newItemNode.id = this.assignItemId(item.id, idExt);
                                newItemNode.type = MAPNODETYPE.NODE
                                newItemNode.level = item.level;
                                newItemNode.titleText = item.titleText;
                                newItemNode.image = item.image;
                                newItemNode.innerImage = item.innerImage;
                                newItemNode.looped = item.looped;
                                newItemNode.expanded = item.expanded;
                                newItemNode.nodeLanguage = item.language;
                                newItemNode.locked = item.locked;
                                const latex = this.mathService.getLatexFromNode(node);
                                newItemNode.hasMath = (latex != null && latex.trim() !== '');
                                newItemNode.mathLatex = latex ? latex : '';
                                previousEdge = null;
                            } else if (item.type === MAPNODETYPE.EDGE) {
                                newItemNode.edgeId = item.id;
                                newItemNode.edgeText = item.titleText;
                                newItemNode.edgeLanguage = item.language;
                                previousEdge = newItemNode;
                            }

                            if (item.type === MAPNODETYPE.NODE) {
                                const parentLevel = (item.level === Math.trunc(item.level) ? item.level - 1 : item.level - 0.5);
                                const parentItem = parents.get(parentLevel);
                                if (parentItem) {
                                    newItemNode.parent = parentItem;
                                    if (!parentItem.children) {
                                        parentItem.children = new Array<ItemNode>();
                                    }
                                    parentItem.children.push(newItemNode);
                                } else {
                                    itemNodes.push(newItemNode);
                                }
                                parents.set(newItemNode.level, newItemNode);
                            }
                        } else {
                            const tableId = node.nodeTable.tableId;
                            let tableMapNode = tableIdMap.get(tableId);
                            if (!tableMapNode) {
                                // Unvisited table: add Table element
                                const newTableMapNode = new ItemNode();
                                newTableMapNode.type = MAPNODETYPE.TABLE;
                                newTableMapNode.id = tableId + '_0';
                                newTableMapNode.level = 1;
                                newTableMapNode.titleText = this.smService.sm.STRINGS.ARRANGE.TABLE_NAME + ' ' + tableId;
                                // Add table id in map to retrieve Table element and add child cells
                                tableIdMap.set(tableId, newTableMapNode);
                                tableMapNode = newTableMapNode;
                                itemNodes.push(tableMapNode);
                            }
                            // Add cell to table
                            if (tableMapNode) {
                                const mapCell = new ItemNode();
                                mapCell.type = MAPNODETYPE.NODE;
                                mapCell.tableId = tableId;
                                mapCell.id = this.assignItemId(item.id, idExt);
                                mapCell.level = tableMapNode.level + 1;
                                mapCell.titleText = item.titleText;
                                mapCell.image = item.image;
                                mapCell.innerImage = item.innerImage;
                                mapCell.looped = item.looped;
                                mapCell.parent = tableMapNode;
                                mapCell.nodeLanguage = item.language;
                                mapCell.locked = item.locked;
                                const latex = this.mathService.getLatexFromNode(node);
                                mapCell.hasMath = latex != null && latex.trim() !== '';
                                mapCell.mathLatex = latex ? latex : '';
                                if (tableMapNode) {
                                    if (!tableMapNode.children) {
                                        tableMapNode.children = new Array<ItemNode>();
                                    }
                                    tableMapNode.children.push(mapCell);
                                } else {
                                    itemNodes.push(mapCell);
                                }
                            }
                        }
                    }
                }
            }
            this.onOutlineUpdated.next(itemNodes)
        }
    }

}
