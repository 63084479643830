import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { extract, I18nService } from '../core/i18n.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { GoogleService } from '../core/google/google.service';
import { SmeService } from '../core/sme/sme.service';
import { Logger } from '../core/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MessageBoxService } from '../shared/dialog/messagebox.service';
import { MapOperationType } from '../shared/map-operations';
import { environment } from '../../environments/environment';
import { FirebaseService } from '../core/firebase/firebase.service';


declare let google: any;

// const SME_MIMETYPES = 'application/supermappe,application/supermappeevo,application/sme,application/x-zip,text/plain';
// const SME_MIMETYPES = 'application/supermappe,application/supermappeevo,application/sme,application/x-zip,text/plain';
const SME_MIMETYPES = 'application/supermappe,application/supermappeevo,application/sme,application/x-zip';


const logger: Logger = new Logger('GdrivePickerComponent');

@Component({
    selector: 'app-gdrive-picker',
    templateUrl: './gdrive-picker.component.html',
    styleUrls: ['./gdrive-picker.component.scss']
})
export class GdrivePickerComponent implements OnInit, OnDestroy {

    isLoading = false;
    error = '';
    picker: any;
    accessToken = '';

    createSubscription: Subscription | undefined;
    statOpenMapSubscription: Subscription | undefined;
    accessTokenSubscription: Subscription | undefined;

    constructor(private router: Router,
        private zone: NgZone,
        private authenticationService: AuthenticationService,
        private i18nService: I18nService,
        private translateService: TranslateService,
        private googleService: GoogleService,
        private smeService: SmeService,
        private firebaseService: FirebaseService,
        private messageBoxService: MessageBoxService) { }

    ngOnInit() {
        if (this.picker) {
            this.picker.setVisible();
        } else {
            let accessToken: string;
            this.isLoading=true;
            this.accessTokenSubscription = this.smeService.getAccessToken().subscribe({
                next: (_accessToken: string) => {
                    accessToken = _accessToken;
                    this.accessToken = accessToken;
                    this.googleService.loadPicker()
                        .then(() => {
                            this.createPicker(accessToken);
                            this.isLoading=false;
                            this.picker.setVisible(true);
                        }).catch((err: any) => {

                            if (err === '401') {
                                if (this.authenticationService.isNativegoogleUser()) {
                                    this.router.navigate(['loggedin'], { fragment: 'expired=1' });
                                } else {
                                   if (this.authenticationService.credentials) {
                                     
                                    const id = this.authenticationService.credentials.firebaseUserId;
                                    this.messageBoxService.showTextMessage(this.messageBoxService.MODE_TYPE.OK,
                                        this.translateService.instant('WARNING'),
                                        this.translateService.instant('RICONNNECT_GOOGLE_ACCOUNT'),
                                    ).subscribe(() => {
                                    this.router.navigate(['/user-profile', id], { fragment: 'highlight=1' });
                                    });
                                   } else {
                                    this.router.navigate(['loggedin'], { fragment: 'expired=1' });
                                   }
                                }

                            } else {
                                logger.error('AUTH ERROR: ' + JSON.stringify(err));
                                this.error = extract('GDRIVE_PICKER_ERROR_LOADING_PICKER');
                                this.showError(this.error);
                            }
                        });
                },
                error: (error: any) => {
                    this.isLoading = false;
                    if (this.authenticationService.credentials && !this.authenticationService.isNativegoogleUser()) {
                                     
                        const id = this.authenticationService.credentials.firebaseUserId;
                        this.messageBoxService.showTextMessage(this.messageBoxService.MODE_TYPE.OK,
                            this.translateService.instant('WARNING'),
                            this.translateService.instant('RICONNNECT_GOOGLE_ACCOUNT'),
                        ).subscribe(() => {
                        this.router.navigate(['/user-profile', id]);
                        });
                    } else {
                        this.authenticationService.logout().subscribe(() => this.router.navigate(['loggedin'], { fragment: 'expired=1' }));
                    }
                    
                }
            });
        }
    }

    ngOnDestroy(): void {
        if (this.createSubscription) { this.createSubscription.unsubscribe(); }
        if (this.statOpenMapSubscription) { this.statOpenMapSubscription.unsubscribe(); }
        if (this.accessTokenSubscription) { this.accessTokenSubscription.unsubscribe(); }
        if (this.picker) {
            this.closePicker();
            this.picker.dispose();
            this.picker = null;
        }
    }

    backToHome() {
        this.router.navigate(['home']);
    }

    closePicker() {
        if (this.picker) {
            this.picker.setVisible(false);
        }
    }

    createPicker(accessToken: string): void {
        if (!this.picker) {
            const language = this.i18nService.language;
            const view = new google.picker.View(google.picker.ViewId.DOCS);
            view.setMimeTypes(SME_MIMETYPES);
            view.setQuery('*.sme');
            const APP_ID = environment.configFirebase.messagingSenderId;
            this.picker = new google.picker.PickerBuilder()
                .addView(view).enableFeature(google.picker.Feature.SUPPORT_DRIVES)
                .setAppId(APP_ID)
                .setOrigin(window.origin)
                .setOAuthToken(accessToken)
                .setLocale(language)
                .enableFeature(google.picker.Feature.NAV_HIDDEN)
                .setCallback(this.onPickerChosen.bind(this))
                .build();
        }
    }

    onPickerChosen(data: any) {
        this.zone.run(() => {
            const action = data[google.picker.Response.ACTION];
            if (action === google.picker.Action.CANCEL) {
                this.closePicker();
                this.router.navigate(['/home'], { replaceUrl: true });
            } else if (action === google.picker.Action.PICKED) {
                this.closePicker();
                this.isLoading = true;
                const doc = data[google.picker.Response.DOCUMENTS][0];
                const url = doc[google.picker.Document.URL];
                const name = doc.name;
                const fileId: string = doc[google.picker.Document.ID];
                const mimeType: string = doc.mimeType;
                logger.info(`+++Drive chosen+++`);
                logger.info(`id:${fileId}`);
                logger.info(`mimeType:${mimeType}`);
                logger.info(`url:${url} `);
                logger.info(`name:${name}`);
                logger.info(`++++++++++++++++++`);

                if (name && name.toLowerCase().endsWith('.sme') && (mimeType === 'application/supermappe' || mimeType === 'application/x-zip')) {


                    if (this.createSubscription) { this.createSubscription.unsubscribe(); }
                    this.createSubscription = this.smeService.createWorkMapFromGoogleDrive(fileId)
                        .subscribe({
                            next: (res: any) => {
                                // this.router.navigate(['/map-edit', mapId], { replaceUrl: true });
                                if (res) {
                                    this.isLoading = false;
                                    logger.info(`Map ${res.mapId} loaded`);
                                    if (this.statOpenMapSubscription) { this.statOpenMapSubscription.unsubscribe(); }
                                    this.statOpenMapSubscription = this.smeService.addCustomMapStat(res.mapId, MapOperationType.STAT_OPENMAP, this.authenticationService.getUserEmail()).subscribe((_data: any) => { });
                                    this.firebaseService.addSharedStateChangeListener('', res.mapId, this.authenticationService.getUserEmail())
                                    if (res.readonly) {
                                        this.router.navigate(['map-view', res.mapId]);
                                        // window.location.href = 'map-view/' + res.mapId;
                                    } else {
                                        this.router.navigate(['map-edit', res.mapId]);
                                        // window.location.href = 'map-edit/' + res.mapId;
                                    }
                                } else {
                                    logger.error('Error loading google file ' + fileId);
                                    this.isLoading = false;
                                    this.error = 'DATA_NOT_EXISTS';
                                    this.showError(this.error);
                                }

                            },
                            error: (error: any) => {
                                logger.error('Error loading google file ' + fileId + ': ' + error);
                                this.isLoading = false;
                                let errorCode = '';
                                if (error.status === 500 && error.error && error.error.message === 'The domain administrators have disabled Drive apps.') {
                                    this.messageBoxService.showAdminDisabled3rdPartyApps().subscribe(() => {
                                        this.router.navigate(['home/']);
                                    });
                                } else if (error.status === 504) {
                                    errorCode = 'GATEWAY_TIMEOUT';
                                } else {
                                    errorCode = (error.code) ? error.code : error.statusText;
                                }
                                this.showError(errorCode);
                            }
                        });
                }
            }
        });


    }

    private showError(errorCode: string) {
        this.error = this.translateService.instant(extract(errorCode));
    }
}

